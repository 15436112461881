import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoWorkspaceAccessComponent } from './no-workspace-access.component';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { HuiAvatarModule } from '@app.cobiro.com/shared/hui/avatar';
import { HuiLoaderContainerModule } from '@app.cobiro.com/shared/hui/loader-container';

@NgModule({
  declarations: [NoWorkspaceAccessComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    HuiLoaderContainerModule,
    MatIconModule,
    HuiAvatarModule,
    LanguageModule,
  ],
  exports: [NoWorkspaceAccessComponent],
})
export class NoWorkspaceAccessComponentModule {}
