import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const IS_SIDEBAR_SHOWN_COMMAND = new InjectionToken<IsSidebarShownCommandPort>(
  'IS_SIDEBAR_SHOWN_COMMAND',
);

export interface IsSidebarShownCommandPort {
  isSidebarShown(): Observable<boolean>;
}
