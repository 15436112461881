import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import {
  GETS_DISCOUNT_COMMAND,
  GetsDiscountCommandPort,
} from '../../../../../../../../application/ports/primary/payments/gets-discount.command-port';
import {
  GETS_PLAN_ESTIMATE_QUERY,
  GetsPlanEstimateQueryPort,
} from '../../../../../../../../application/ports/primary/payments/gets-plan-estimate.query-port';
import {
  DiscountDto,
  DISCOUNT_TYPES,
} from '../../../../../../../../application/ports/secondary/dto/payments/discount.dto';
import { Observable } from 'rxjs';
import { PlanEstimateQuery } from '../../../../../../../../application/ports/primary/payments/plan-estimate.query';

@Component({
  selector: 'lib-cobiro-pro-payment-summary',
  templateUrl: './payment-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CobiroProPaymentSummaryComponent {
  @Input() isTrial: boolean;
  trialPeriod = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000);
  readonly discount$: Observable<DiscountDto> = this._getsDiscount.getDiscount();
  readonly planEstimate$: Observable<PlanEstimateQuery> = this._getPlanEstimate.getPlanEstimate();

  constructor(
    @Inject(GETS_PLAN_ESTIMATE_QUERY)
    private readonly _getPlanEstimate: GetsPlanEstimateQueryPort,
    @Inject(GETS_DISCOUNT_COMMAND)
    private readonly _getsDiscount: GetsDiscountCommandPort,
  ) {}
  private _discountUnitMap = new Map<string, string>([
    ['percentage', '%'],
    ['flat', '$'],
  ]);

  getDiscountValue(discount: DiscountDto): string {
    const value = discount.type === DISCOUNT_TYPES.flat ? discount.value / 100 : discount.value;
    return `${value}${this._discountUnitMap.get(discount.type)}`;
  }
}
