import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { GetsEmailConsentsDtoPort } from '../../../../application/ports/secondary/gets-email-consents.dto-port';
import { SavesEmailConsentDtoPort } from '../../../../application/ports/secondary/saves-email-consent.dto-port';
import { EmailConsentDto } from '../../../../application/ports/secondary/email-consent.dto';

import { HasData } from '@cobiro/jsonapi';
import { GatewayClient } from '@app.cobiro.com/common/gateway';

interface EmailConsentsAttributes {
  [key: string]: boolean;
}

@Injectable()
export class HttpMarketingPreferencesService
  implements SavesEmailConsentDtoPort, GetsEmailConsentsDtoPort
{
  constructor(private readonly _client: GatewayClient) {}

  saveEmailConsent(channel: string, consent: boolean): Observable<boolean> {
    const payload = {
      data: {
        type: 'email-consents',
        attributes: {
          [channel]: consent,
        },
      },
    };
    return this._client.patch(`v1/users/email-consents`, payload).pipe(map(() => true));
  }

  getEmailConsent(): Observable<EmailConsentDto[]> {
    return this._client.get<HasData<EmailConsentsAttributes>>(`v1/users/email-consents`).pipe(
      map(response =>
        Object.keys(response.data.attributes).map(channel => ({
          name: channel,
          consent: response.data.attributes[channel],
        })),
      ),
    );
  }
}
