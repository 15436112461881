import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CobiroProPageHeaderComponent } from './cobiro-pro-page-header.component';
import { PageHeaderTitleDirective } from './cobiro-pro-page-header-title.directive';

@NgModule({
  imports: [CommonModule, MatIconModule],
  declarations: [CobiroProPageHeaderComponent, PageHeaderTitleDirective],
  exports: [CobiroProPageHeaderComponent, PageHeaderTitleDirective],
})
export class CobiroProPageHeaderComponentModule {}
