import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, distinctUntilKeyChanged, map, take, tap } from 'rxjs/operators';
import { PlanPaymentCompletedEvent, PlanPaymentStartedEvent } from '@app.cobiro.com/core/events';
import { PAYMENT_STATUS } from '../../domain/payment-status.enum';
import { FEATURE_CONTEXT_STORAGE } from '../../domain/storage/feature-context.storage';
import { ReactiveSingleValueStorage } from '@app.cobiro.com/core/storage';
import { PLAN_ESTIMATE_STORAGE } from '../../domain/storage/plan-estimate.storage';
import { PlanEstimate } from '../../domain/plan-estimate';
import { SELECTED_PLAN_STORAGE } from '../../domain/storage/selected-plan.storage';
import { SelectedPlan } from '../../domain/selected-plan';
import { DISCOUNT_STORAGE } from '../../domain/storage/discount.storage';
import { Discount } from '../../domain/discount';
import { APPLICATION_BUS, ApplicationBus } from '@cobiro/eda';
import {
  CREATES_NEW_SUBSCRIPTION_DTO_PORT,
  CreatesNewSubscriptionDtoPort,
} from '../../domain/creates-new-subscription.dto-port';
import { UpdateDtoError } from '../../infrastructure/update-billing-info/updates-user-billing-dto.port';
import { UPDATE_ERROR_FIELDS } from '../../domain/update-error-fields';
import { HuiAlert } from '@app.cobiro.com/shared/hui/alert';
import { GetsCreateNewSubscriptionUpdateErrorQueryPort } from '../ports/primary/gets-create-new-subscription-update-error.query-port';

@Injectable()
export class PaymentPlanPurchaseState implements GetsCreateNewSubscriptionUpdateErrorQueryPort {
  private readonly _updateErrors = new BehaviorSubject<UPDATE_ERROR_FIELDS | null>(null);
  private readonly _updateErrorMap = new Map<UPDATE_ERROR_FIELDS, string>([
    [UPDATE_ERROR_FIELDS.vatNumber, '_invalid_vat_number'],
    [UPDATE_ERROR_FIELDS.zipCode, '_invalid_zip_code'],
  ]);

  constructor(
    @Inject(APPLICATION_BUS) private _applicationBus: ApplicationBus,
    @Inject(CREATES_NEW_SUBSCRIPTION_DTO_PORT)
    private _createsNewSubscription: CreatesNewSubscriptionDtoPort,
    @Inject(FEATURE_CONTEXT_STORAGE)
    private _featureContextStorage: ReactiveSingleValueStorage<string>,
    @Inject(PLAN_ESTIMATE_STORAGE)
    private _planEstimateStorage: ReactiveSingleValueStorage<PlanEstimate>,
    @Inject(SELECTED_PLAN_STORAGE)
    private _selectedPlanStorage: ReactiveSingleValueStorage<SelectedPlan>,
    @Inject(DISCOUNT_STORAGE)
    private _discountCodeStorage: ReactiveSingleValueStorage<Discount>,
    private _huiAlert: HuiAlert,
  ) {}

  createNewSubscription(
    cardId: string,
    preHook: Observable<boolean>,
  ): Observable<PAYMENT_STATUS | never> {
    return preHook.pipe(
      tap(() => {
        this._updateErrors.next(null);
      }),
      catchError((error: UpdateDtoError) => {
        this._updateErrors.next(error.errorField);
        this._huiAlert.open('error', this._updateErrorMap.get(error.errorField));
        return of(false);
      }),
      concatMap(billingUpdateSuccess =>
        billingUpdateSuccess
          ? this._featureContextStorage.select().pipe(
              take(1),
              tap(featureContext => {
                this._applicationBus.dispatch(new PlanPaymentStartedEvent(featureContext));
              }),
              concatMap(() => this._selectedPlanStorage.select()),
              distinctUntilKeyChanged('id'),
              map(plan => plan.id),
              take(1),
              concatMap(selectedPlanId =>
                selectedPlanId
                  ? this._createsNewSubscription.createNewSubscription({
                      cardId,
                      planId: selectedPlanId,
                      discountCode: this._discountCodeStorage.get()?.code,
                    })
                  : throwError('No plan selected'),
              ),
              tap((paymentStatus: PAYMENT_STATUS) => {
                const success = paymentStatus === PAYMENT_STATUS.SUCCESS;
                this._trackPayment(
                  success,
                  `saved-card-${cardId}-${new Date().getTime()}`,
                ).subscribe();
              }),
            )
          : throwError(false),
      ),
    );
  }

  getCreateNewSubscriptionUpdateError(): Observable<UPDATE_ERROR_FIELDS> {
    return this._updateErrors.asObservable();
  }

  private _trackPayment(paymentSuccess: boolean, transactionId: string): Observable<boolean> {
    return combineLatest([
      this._selectedPlanStorage.select(),
      this._planEstimateStorage.select(),
    ]).pipe(
      take(1),
      tap(([plan, estimate]) => {
        this._applicationBus.dispatch(
          new PlanPaymentCompletedEvent(
            paymentSuccess,
            plan.id,
            plan.price,
            plan.name,
            plan.period,
            estimate.gross,
            estimate.vatValue,
            transactionId,
            false,
          ),
        );
      }),
      map(() => paymentSuccess),
    );
  }
}
