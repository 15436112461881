/* eslint-disable max-lines-per-function */
import { Inject, Injectable } from '@angular/core';
import { Observable, filter, map, of, switchMap, take, tap, throwError } from 'rxjs';
import {
  SETS_SELECTED_TEAM_COMMAND,
  SetsSelectedTeamCommandPort,
} from '@app.cobiro.com/cobiro-pro/context';
import { HuiAlert } from '@app.cobiro.com/shared/hui/alert';
import {
  CREATES_TEAM_DTO_PORT,
  CreatesTeamDtoPort,
} from '../../ports/secondary/creates-team.dto-port';
import { CompanyTypeQuery } from '../../ports/primary/company-type.query';
import { NumberOfClientsQuery } from '../../ports/primary/number-of-clients.query';
import { NumberOfEmployeesQuery } from '../../ports/primary/number-of-employees.query';
import { GetsCompanyTypesQueryPort } from '../../ports/primary/gets-company-types.query-port';
import { GetsNumberOfClientsQueryPort } from '../../ports/primary/gets-number-of-clients.query-port';
import { GetsNumberOfEmployeesQueryPort } from '../../ports/primary/gets-number-of-employees.query-port';
import { CreatesTeamQueryPort } from '../../ports/primary/creates-team.query-port';
import { MatDialog } from '@angular/material/dialog';
import { CreateTeamComponent } from '../../../adapters/primary/ui/create-team/create-team.component';
import {
  GETS_USER_DETAILS_DTO,
  GetsUserDetailsDtoPort,
} from '../../ports/secondary/gets-user-details.dto-port';
import { GETS_USER_FROM_TOKEN, GetsUserFromToken } from '@app.cobiro.com/user/core';
import { UserDetailsDTO } from '../../ports/secondary/user-details.dto';
import { APPLICATION_BUS, ApplicationBus } from '@cobiro/eda';
import { CobiroProTeamsChangedEvent } from '@app.cobiro.com/core/events';

enum CompanyType {
  Agency = 'agency',
  Individual = 'individual',
  EcommerceMerchant = 'ecommerce_merchant',
}

@Injectable()
export class TeamsState
  implements
    GetsCompanyTypesQueryPort,
    GetsNumberOfClientsQueryPort,
    GetsNumberOfEmployeesQueryPort,
    CreatesTeamQueryPort
{
  constructor(
    @Inject(CREATES_TEAM_DTO_PORT)
    private readonly _createsTeamDtoPort: CreatesTeamDtoPort,
    @Inject(SETS_SELECTED_TEAM_COMMAND)
    private readonly _setsSelectedTeamCommand: SetsSelectedTeamCommandPort,
    @Inject(GETS_USER_DETAILS_DTO)
    private readonly _getsUserDetailsDtoPort: GetsUserDetailsDtoPort,
    @Inject(GETS_USER_FROM_TOKEN)
    private readonly _getsUser: GetsUserFromToken,
    @Inject(APPLICATION_BUS)
    private readonly _applicationBus: ApplicationBus,
    private readonly _matDialog: MatDialog,
    private readonly _alert: HuiAlert,
  ) {}

  createTeam(): Observable<void> {
    const user = this._getsUser.get();
    return this._matDialog
      .open(CreateTeamComponent, {
        panelClass: 'cs-mat-dialog',
      })
      .afterClosed()
      .pipe(
        take(1),
        filter(Boolean),
        switchMap(teamFormData =>
          this._getsUserDetailsDtoPort.getUserDetails({ userId: user.userId }).pipe(
            switchMap((res: UserDetailsDTO) =>
              this._createsTeamDtoPort.createTeam({ team: teamFormData, owner: res }).pipe(
                switchMap((id: string) => {
                  this._applicationBus.dispatch(new CobiroProTeamsChangedEvent());

                  return this._setsSelectedTeamCommand.setSelectedTeam(
                    id,
                    teamFormData.companyName,
                    'owner',
                    res.avatarUrl,
                  );
                }),
                tap({
                  next: () => {
                    this._alert.open('success', 'cobiro_pro_team_create_new_success');
                  },
                  error: err => {
                    this._alert.open('error', '_something_went_wrong');
                    return throwError(() => err);
                  },
                }),
              ),
            ),
          ),
        ),
        map(() => void 0),
      );
  }

  // createTeam(dto: TeamQuery): Observable<boolean> {
  //   return this._createsTeamDtoPort.createTeam(dto).pipe(
  //     switchMap(() => this._getsCobiroProContextQuery.getContext()),
  //     take(1),
  //     switchMap((context: CobiroProContextQuery) =>
  //       this._setsSelectedTeamCommand.setSelectedTeam(
  //         context.selectedTeamId,
  //         dto.companyName,
  //         context.selectedTeamUserRole,
  //         context.selectedTeamAvatar,
  //       ),
  //     ),
  //     map(() => true),
  //     tap({
  //       next: () =>
  //         this._alert.open('success', 'cobiro_pro_workspace_settings_form_submit_success'),
  //       error: () => this._alert.open('error', 'cobiro_pro_workspace_settings_form_submit_error'),
  //     }),
  //     catchError(() => of(false)),
  //   );
  // }

  getAvailableCompanyTypes(): Observable<CompanyTypeQuery[]> {
    return of([
      new CompanyTypeQuery('cobiro_pro_company_details_type_agency', CompanyType.Agency),
      new CompanyTypeQuery('cobiro_pro_company_details_type_freelancer', CompanyType.Individual),
      new CompanyTypeQuery(
        'cobiro_pro_company_details_type_merchant',
        CompanyType.EcommerceMerchant,
      ),
    ]);
  }

  getAvailableNumberOfClients(): Observable<NumberOfClientsQuery[]> {
    return of([
      new NumberOfClientsQuery('1', 1),
      new NumberOfClientsQuery('2-5', 5),
      new NumberOfClientsQuery('6-10', 10),
      new NumberOfClientsQuery('11-20', 20),
      new NumberOfClientsQuery('> 21', 21),
    ]);
  }

  getAvailableNumberOfEmployees(): Observable<NumberOfEmployeesQuery[]> {
    return of([
      new NumberOfEmployeesQuery('1-10', 10),
      new NumberOfEmployeesQuery('11-25', 25),
      new NumberOfEmployeesQuery('26-50', 50),
      new NumberOfEmployeesQuery('51-100', 100),
    ]);
  }
}
