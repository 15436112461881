<ng-container *huiLoaderContainer="isDummyIntegrationCreated$ | async as config">
  <div class="cs-form d-flex flex-column w-100 h-100 justify-content-between">
    <div
      class="position-relative d-flex flex-column justify-content-between align-items-center cs-bg-shade-0"
    >
      <p *ngIf="config.errors.length > 0" class="text-center cs-mb-40 cs-mt-20">
        <span class="cs-title-7"
          >{{ 'cobiro_pro_label_manager_non_css_integration_error_title' | language }}.</span
        >
        {{
          'cobiro_pro_label_manager_non_css_integration_error_description'
            | language : config.errors.join(' and ')
        }}
      </p>
      <p *ngIf="config.errors.length === 0" class="text-center cs-mb-40 cs-mt-20">
        {{ 'cobiro_pro_label_manager_non_css_integration_introduction' | language }}
      </p>
      <div class="d-flex cs-mb-10 cs-ml-2">
        <mat-checkbox
          [formControl]="isNoCache"
          class="cs-mat-checkbox cs-mat-checkbox-shade-10 cs-mat-checkbox-without-ripple"
          data-selector="non-css-integration-no cache-checkbox"
        >
          <span class="cs-title-8">{{
            'cobiro_pro_label_manager_non_css_integration_no_cache_checkbox' | language
          }}</span>
        </mat-checkbox>
      </div>
    </div>
    <div class="d-flex justify-content-between cs-mt-20">
      <a
        class="cs-btn cs-btn-flat"
        href="https://support.cobiro.com/hc/en-us/requests/new"
        target="_blank"
      >
        {{ 'cobiro_pro_label_manager_config_window_help_button' | language }}
      </a>
      <button
        *ngIf="!config.isCreated"
        class="cs-btn cs-btn-primary cs-width-160-xs"
        data-selector="proceed-non-css-linking-button"
        type="button"
        (click)="createIntegration()"
      >
        {{ 'cobiro_pro_label_manager_non_css_integration_proceed_button' | language }}
      </button>
      <button
        *ngIf="config.isCreated"
        class="cs-btn cs-btn-primary cs-width-160-xs"
        data-selector="retry-non-css-linking-button"
        type="button"
        (click)="goToNextStep()"
      >
        {{ '_retry' | language }}
      </button>
    </div>
  </div>
</ng-container>
