<div
  class="position-relative cs-max-width-625-xs d-flex flex-column mx-auto cs-ph-40 cs-pb-40 justify-content-between align-items-center cs-bg-shade-0"
  *ngIf="isTrial$ | async as isTrial"
>
  <div class="d-flex flex-column w-100 h-100 justify-content-between">
    <div class="d-flex flex-column">
      <h3 class="cs-title-4 cs-mb-15" data-selector="cobiro-pro-confirm-payment-header">
        {{ 'cobiro_pro_setup_confirmation' | language }}
      </h3>
      <p class="cs-text-1" data-selector="cobiro-pro-confirm-payment-sub-header">
        {{ 'cobiro_pro_setup_confirmation_description' | language }}
      </p>
    </div>
    <div class="cs-max-height-500-xs overflow-y-auto">
      <lib-cobiro-pro-card-list></lib-cobiro-pro-card-list>
    </div>
    <lib-cobiro-pro-discount-code></lib-cobiro-pro-discount-code>
    <lib-cobiro-pro-payment-summary [isTrial]="isTrial.value"></lib-cobiro-pro-payment-summary>
  </div>
  <div class="d-flex justify-content-end cs-mt-20 w-100">
    <button
      class="cs-btn cs-btn-primary w-100"
      data-selector="cobiro-pro-create-subscription-button"
      [disabled]="buttonLoading"
      [class.is-loading]="buttonLoading"
      (click)="onCreateSubscription(isTrial.value)"
    >
      {{ 'payment_plans_start_subscription' | language }}
      <mat-icon svgIcon="arrow-right"></mat-icon>
    </button>
  </div>
</div>
