import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { CreateTeamComponent } from './create-team.component';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { HuiInputModule } from '@app.cobiro.com/shared/hui/input';

@NgModule({
  declarations: [CreateTeamComponent],
  imports: [
    CommonModule,
    LanguageModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatOptionModule,
    ReactiveFormsModule,
    MatIconModule,
    HuiInputModule,
    MatFormFieldModule,
    MatSelectModule,
  ],
  exports: [CreateTeamComponent],
})
export class CreateTeamComponentModule {}
