import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { HasData } from '@cobiro/jsonapi';
import { map } from 'rxjs/operators';
import { GetsUserDetailsDtoPort } from '../../../../application/ports/secondary/gets-user-details.dto-port';
import { UserDetailsDTO } from '../../../../application/ports/secondary/user-details.dto';
import { GetsUserDetailsDto } from '../../../../application/ports/secondary/gets-user-details.dto';
export interface UserDetailsAttributes {
  email: string;
  partnerId: number;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  country: string;
  group: string;
  createdAt: number;
  avatarUrl: string;
}

@Injectable()
export class HttpGetsUserDetailsService implements GetsUserDetailsDtoPort {
  constructor(private readonly _client: GatewayClient) {}
  getUserDetails(dto: GetsUserDetailsDto): Observable<UserDetailsDTO> {
    return this._client.get<HasData<UserDetailsAttributes>>(`v2/users/${dto.userId}/details`).pipe(
      map(response => ({
        email: response.data.attributes.email,
        firstName: response.data.attributes.firstName,
        lastName: response.data.attributes.lastName,
        country: response.data.attributes.country,
        phoneNumber: response.data.attributes.phoneNumber,
        avatarUrl: response.data.attributes.avatarUrl,
      })),
    );
  }
}
