export enum USER_ROLE {
  owner = 'owner',
  admin = 'admin',
  basic_user = 'basic_user',
}

export interface TeamDTO {
  readonly id: string;
  readonly name: string;
  readonly role: USER_ROLE;
  readonly avatar: string | null;
}
