import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CobiroProTopBarComponent } from './cobiro-pro-top-bar.component';
import { CobiroProUserMenuComponentModule } from '../cobiro-pro-user-menu/user-menu.component-module';
import { MatIconModule } from '@angular/material/icon';
import { CobiroProTopBarDropdownComponentModule } from '../cobiro-pro-top-bar-dropdown/cobiro-pro-top-bar-dropdown.component-module';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [CobiroProTopBarComponent],
  imports: [
    CommonModule,
    CobiroProUserMenuComponentModule,
    CobiroProTopBarDropdownComponentModule,
    MatIconModule,
    LanguageModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  exports: [CobiroProTopBarComponent],
})
export class CobiroProTopBarComponentModule {}
