import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { SetsSearchPhraseCommand } from './sets-search-phrase.command';

export interface SetsAllTeamClientsSearchPhraseCommandPort {
  setSearchPhrase(command: SetsSearchPhraseCommand): Observable<void>;
}

export const SETS_ALL_TEAM_CLIENTS_LIST_SEARCH_PHRASE_COMMAND =
  new InjectionToken<SetsAllTeamClientsSearchPhraseCommandPort>(
    'SETS_ALL_TEAM_CLIENTS_LIST_SEARCH_PHRASE_COMMAND',
  );
