import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const IS_TRIAL_PERIOD_QUERY = new InjectionToken<IsTrialPeriodQueryPort>(
  'IS_TRIAL_PERIOD_QUERY',
);

export interface IsTrialPeriodQueryPort {
  isTrial(): Observable<boolean>;
}
