import { NgModule } from '@angular/core';
import { HttpTeamsService } from './http-teams.service';
import { CREATES_TEAM_DTO_PORT } from '../../../../application/ports/secondary/creates-team.dto-port';

@NgModule({
  declarations: [],
  providers: [HttpTeamsService, { provide: CREATES_TEAM_DTO_PORT, useExisting: HttpTeamsService }],
  exports: [],
})
export class HttpTeamsServiceModule {}
