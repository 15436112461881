import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { WorkspaceQuery } from '../../../../../application/ports/primary/workspace.query';
import { Observable, firstValueFrom, map, take, tap } from 'rxjs';
import {
  GetsWorkspacesListQuery,
  GETS_WORKSPACES_LIST_QUERY,
} from '../../../../../application/ports/primary/gets-workspace-list.query';
import { ActivatedRoute, Router } from '@angular/router';
import {
  SWITCHES_WORKSPACE_COMMAND,
  SwitchesWorkspaceCommandPort,
} from '../../../../../application/ports/primary/switches-workspace.command-port';
import {
  IS_USER_ADMIN_QUERY,
  IsUserAdminQueryPort,
} from '../../../../../application/ports/primary/is-user-admin.query-port';

@Component({
  selector: 'lib-cobiro-pro-workspace-list-table',
  templateUrl: './workspace-list-table.component.html',
  styleUrls: ['./workspace-list-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceListTableComponent {
  private homeUrl;
  readonly columns = [
    'cobiro_pro_workspaces_table_workspaces_name',
    'cobiro_pro_workspaces_table_users',
    'cobiro_pro_workspaces_table_shops',
    'cobiro_pro_workspaces_table_created_at',
  ];
  readonly workspaces$: Observable<WorkspaceQuery[]> =
    this._getsWorkspacesListQuery.getWorkspaceListQuery();

  readonly isUserAdmin$ = this._isUserAdminQueryPort.isUserAdmin().pipe(
    take(1),
    map(res => ({ value: res })),
    tap((res: { value: boolean }) => {
      if (res.value) {
        this.homeUrl = '../../home/';
        this.columns.push(
          ...['cobiro_pro_workspaces_table_edit', 'cobiro_pro_workspaces_table_delete'],
        );
      } else {
        this.homeUrl = '../home/';
      }
    }),
  );

  constructor(
    @Inject(GETS_WORKSPACES_LIST_QUERY)
    private readonly _getsWorkspacesListQuery: GetsWorkspacesListQuery,
    @Inject(SWITCHES_WORKSPACE_COMMAND)
    private readonly _switchesWorkspaceCommandPort: SwitchesWorkspaceCommandPort,
    @Inject(IS_USER_ADMIN_QUERY)
    private readonly _isUserAdminQueryPort: IsUserAdminQueryPort,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
  ) {}

  private onEditButtonClicked(workspace: WorkspaceQuery): void {
    this._router.navigate([], {
      relativeTo: this._route,
      queryParams: {
        edit: workspace.id,
      },
      queryParamsHandling: 'merge',
    });
  }

  goToWorkspaceUsers(id: string): void {
    this._router.navigate(['../workspaces', id], {
      relativeTo: this._route,
    });
  }

  async goToWorkspace(workspace: WorkspaceQuery): Promise<void> {
    await firstValueFrom(this._switchesWorkspaceCommandPort.switchWorkspace(workspace)).then(() =>
      this._router.navigate([this.homeUrl], {
        relativeTo: this._route,
      }),
    );
  }

  private onDeleteButtonClicked(workspace: WorkspaceQuery): void {
    this._router.navigate([], {
      relativeTo: this._route,
      queryParams: {
        delete: workspace.id,
      },
      queryParamsHandling: 'merge',
    });
  }
}
