import { Observable } from 'rxjs';
import { PaginationQuery } from '@app.cobiro.com/core/pagination';
import { InjectionToken } from '@angular/core';

export interface GetsAllTeamClientsListPaginationQueryPort {
  getAllTeamClientListPagination(): Observable<PaginationQuery>;
}

export const GETS_ALL_TEAM_CLIENTS_LIST_PAGINATION_QUERY =
  new InjectionToken<GetsAllTeamClientsListPaginationQueryPort>(
    'GETS_ALL_TEAM_CLIENTS_LIST_PAGINATION_QUERY',
  );
