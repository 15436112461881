/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Inject, Injectable } from '@angular/core';
import { GetsCompanyConfigQueryPort } from '../../ports/primary/gets-company-config.query-port';
import { GetsWorkspaceConfigQueryPort } from '../../ports/primary/gets-workspace-config.query-port';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  of,
  switchMap,
  tap,
  zip,
} from 'rxjs';
import {
  DropdownConfigQuery,
  DropdownItemsQuery,
  ItemAction,
} from '../../ports/primary/dropdown-config.query';
import {
  CobiroProContextQuery,
  GETS_COBIRO_PRO_CONTEXT_QUERY,
  GetsCobiroProContextQueryPort,
  SETS_SELECTED_TEAM_COMMAND,
  SetsSelectedTeamCommandPort,
  TEAM_ID_GETTER,
  TeamIdGetter,
} from '@app.cobiro.com/cobiro-pro/context';
import { APPLICATION_BUS, ApplicationBus } from '@cobiro/eda';
import {
  GETS_ALL_TEAMS_DTO,
  GetsAllTeamsDtoPort,
} from '../../ports/secondary/gets-all-team-dto.port';
import { TeamDTO, USER_ROLE } from '../../ports/secondary/team.dto';
import { TeamQuery } from '../../ports/primary/team.query';
import {
  CobiroProClearStatesEvent,
  CobiroProNoWorkspaceSelectedEvent,
  CobiroProTeamCreatingEvent,
  CobiroProTeamsChangedEvent,
  CobiroProTeamsLoadedEvent,
  CobiroProWorkspaceCreatedEvent,
  CobiroProWorkspaceSwitchedEvent,
  TeamSelectedEvent,
} from '@app.cobiro.com/core/events';
import { HasTeamQueryPort } from '../../ports/primary/has-team-query.port';
import { LoadTeamsCommandPort } from '../../ports/primary/load-teams-command.port';
import { WorkspacesChangedEvent } from '@app.cobiro.com/cobiro-pro-rewrite/workspaces';
import { WorkspaceDto } from '../../ports/secondary/workspace.dto';
import { SetCurrentWorkspaceCommandPort } from '../../ports/primary/set-current-workspace-command.port';
import { WorkspaceQuery } from '../../ports/primary/workspace.query';
import { SetCurrentWorkspaceCommand } from '../../ports/primary/set-current-workspace.command';
import { SetCurrentTeamCommand } from '../../ports/primary/set-current-team.command';
import { SetCurrentTeamCommandPort } from '../../ports/primary/set-current-team-command.port';
import { CreateWorkspaceCommandPort } from '../../ports/primary/create-workspace.command-port';
import { CreateWorkspaceCommand } from '../../ports/primary/create-workspace.command';
import { STORAGE } from '@app.cobiro.com/core/storage';
import { IsSidebarShownCommandPort } from '../../ports/primary/is-sidebar-shown.command-port';
import { ToggleSidebarCommandPort } from '../../ports/primary/toggle-sidebar.command-port';
import { CreateTeamCommandPort } from '../../ports/primary/create-team.command-port';

@Injectable()
export class CobiroProTopBarState
  implements
    GetsCompanyConfigQueryPort,
    GetsWorkspaceConfigQueryPort,
    SetCurrentTeamCommandPort,
    SetCurrentWorkspaceCommandPort,
    HasTeamQueryPort,
    LoadTeamsCommandPort,
    CreateWorkspaceCommandPort,
    IsSidebarShownCommandPort,
    ToggleSidebarCommandPort,
    CreateTeamCommandPort
{
  private _allTeamsSubject$: BehaviorSubject<TeamDTO[] | null> = new BehaviorSubject(null);
  private _allWorkspacesSubject$: BehaviorSubject<WorkspaceDto[] | null> = new BehaviorSubject([]);
  private _isSidebarShown$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    @Inject(GETS_COBIRO_PRO_CONTEXT_QUERY)
    private readonly _getsCobiroProContextQuery: GetsCobiroProContextQueryPort,
    @Inject(GETS_ALL_TEAMS_DTO)
    private readonly _getAllTeam: GetsAllTeamsDtoPort,
    @Inject(APPLICATION_BUS)
    private readonly _applicationBus: ApplicationBus,
    @Inject(SETS_SELECTED_TEAM_COMMAND)
    private readonly _setsSelectedTeamCommand: SetsSelectedTeamCommandPort,
    @Inject(TEAM_ID_GETTER)
    private readonly _teamIdGetter: TeamIdGetter,
    @Inject(STORAGE)
    private readonly _storage: Storage,
  ) {
    this._applicationBus
      .on(WorkspacesChangedEvent)
      .pipe(
        tap((event: WorkspacesChangedEvent) => this._allWorkspacesSubject$.next(event.workspaces)),
      )
      .subscribe();

    this._applicationBus
      .on(CobiroProTeamsChangedEvent)
      .pipe(switchMap(() => this.loadAll()))
      .subscribe();
  }

  createTeam(): Observable<void> {
    return of(this._applicationBus.dispatch(new CobiroProTeamCreatingEvent()));
  }

  isSidebarShown(): Observable<boolean> {
    return this._isSidebarShown$.asObservable();
  }

  toggleSidebar(value: boolean): Observable<void> {
    return of(this._isSidebarShown$.next(value));
  }

  loadAll(): Observable<TeamDTO[]> {
    return this._getAllTeam.getsAll({}).pipe(
      tap((teams: TeamDTO[]) => {
        this._allTeamsSubject$.next(teams);
        this._applicationBus.dispatch(
          new CobiroProTeamsLoadedEvent(
            teams.map(teamDto => ({
              id: teamDto.id,
              name: teamDto.name,
              avatar: teamDto.avatar,
              role: teamDto.role,
            })),
          ),
        );
      }),
    );
  }

  hasTeam(): Observable<boolean> {
    return this._allTeamsSubject$.asObservable().pipe(map(teams => teams && teams.length > 0));
  }

  setCurrentTeam(command: SetCurrentTeamCommand): Observable<void> {
    this._applicationBus.dispatch(new CobiroProClearStatesEvent());
    return zip(
      this._setsSelectedTeamCommand.setSelectedTeam(
        command.team.id,
        command.team.name,
        command.team.role,
        command.team.avatar,
      ),
      of(this._applicationBus.dispatch(new TeamSelectedEvent(command.team.id))),
    ).pipe(map(() => void 0));
  }

  createWorkspace(command: CreateWorkspaceCommand): Observable<void> {
    return of(
      this._applicationBus.dispatch(
        new CobiroProWorkspaceCreatedEvent(command.route, command.shouldBeRedirected),
      ),
    );
  }

  setCurrentWorkspace(command: SetCurrentWorkspaceCommand): Observable<void> {
    return of(
      this._applicationBus.dispatch(
        new CobiroProWorkspaceSwitchedEvent(
          command.workspace.id,
          command.workspace.name,
          command.workspace.avatar,
          command.workspace.membersCount,
          command.workspace.clientCount,
        ),
      ),
    );
  }
  getCompanyConfig(): Observable<DropdownConfigQuery> {
    return combineLatest([
      this._getsCobiroProContextQuery.getContext(),
      this._allTeamsSubject$.asObservable(),
    ]).pipe(
      distinctUntilChanged((previous, current) => {
        const prevContext = (({
          selectedTeamId,
          selectedTeamAvatar,
          selectedTeamName,
          selectedTeamUserRole,
        }) => ({ selectedTeamId, selectedTeamAvatar, selectedTeamName, selectedTeamUserRole }))(
          previous[0],
        );
        const curContext = (({
          selectedTeamId,
          selectedTeamAvatar,
          selectedTeamName,
          selectedTeamUserRole,
        }) => ({ selectedTeamId, selectedTeamAvatar, selectedTeamName, selectedTeamUserRole }))(
          current[0],
        );
        return (
          JSON.stringify(prevContext) === JSON.stringify(curContext) &&
          JSON.stringify(previous[1]) === JSON.stringify(current[1])
        );
      }),
      switchMap(([context, teams]: [CobiroProContextQuery, TeamDTO[]]) => {
        return !context.selectedTeamId && teams.length > 0
          ? this.setCurrentTeam({ team: teams[0] }).pipe(
              map(() => ({ context: context, teams: teams })),
            )
          : of({ context: context, teams: teams });
      }),
      filter(({ context, teams }) => teams.some(team => team.id === context.selectedTeamId)),
      map(({ context, teams }) => {
        const title =
          context.selectedTeamName && teams.length !== 0
            ? context.selectedTeamName
            : 'cobiro_pro_top_bar_company';
        const items = teams.map(
          dto =>
            new DropdownItemsQuery(
              [`/connect/${dto.id}/home`],
              dto.name,
              TeamQuery.fromTeamDTO(dto),
              context.selectedTeamId === dto.id,
            ),
        );
        const actions = Array.of(
          ...(context.selectedTeamUserRole !== USER_ROLE.basic_user && context.selectedTeamId
            ? [
                {
                  routerLink: `/connect/${context.selectedTeamId}/company-settings/members`,
                  translationKey: 'cobiro_pro_top_bar_company_settings',
                  icon: 'shopping-bag',
                },
              ]
            : []),
          ...(teams.length === 0
            ? [
                {
                  action: ItemAction.CREATE,
                  translationKey: 'cobiro_pro_top_bar_create_company',
                  icon: 'plus',
                },
              ]
            : []),
        );
        return new DropdownConfigQuery(title, null, items, actions);
      }),
    );
  }

  getWorkspaceConfig(): Observable<DropdownConfigQuery> {
    return combineLatest([
      this._getsCobiroProContextQuery.getContext(),
      this._allWorkspacesSubject$.asObservable(),
    ]).pipe(
      distinctUntilChanged(
        (previous, current) => JSON.stringify(previous) === JSON.stringify(current),
      ),
      map(([context, workspaces]: [CobiroProContextQuery, WorkspaceDto[]]) => {
        const arr = this._storage.getItem('cobiro-pro-recently-viewed-workspaces')
          ? JSON.parse(this._storage.getItem('cobiro-pro-recently-viewed-workspaces'))
          : [];

        workspaces
          .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
          .sort((a, b) => {
            const index1 = arr[this._teamIdGetter.getTeamId()].indexOf(a.id);
            const index2 = arr[this._teamIdGetter.getTeamId()].indexOf(b.id);
            return (index1 > -1 ? index1 : Infinity) - (index2 > -1 ? index2 : Infinity);
          });
        const title = 'cobiro_pro_top_bar_workspace';
        const items = workspaces
          .slice(0, 5)
          .map(
            dto =>
              new DropdownItemsQuery(
                null,
                dto.name,
                WorkspaceQuery.fromDTO(dto),
                context.selectedWorkspaceId === dto.id,
              ),
          );
        const actions = Array.of(
          {
            routerLink:
              context.selectedTeamUserRole !== USER_ROLE.basic_user
                ? `/connect/${context.selectedTeamId}/company-settings/workspaces`
                : `/connect/${context.selectedTeamId}/workspaces`,
            translationKey: 'cobiro_pro_top_bar_view_all_workspaces',
            icon: 'shopping-bag',
          },
          ...(context.selectedTeamUserRole !== USER_ROLE.basic_user
            ? [
                {
                  action: ItemAction.CREATE,
                  translationKey: 'cobiro_pro_top_bar_create_workspace',
                  icon: 'plus',
                },
              ]
            : []),
        );
        if (!context.selectedWorkspaceId && workspaces.length === 0) {
          this._applicationBus.dispatch(
            new CobiroProNoWorkspaceSelectedEvent(this._teamIdGetter.getTeamId()),
          );
        }
        return new DropdownConfigQuery(title, 'cobiro_pro_top_bar_viewed_recently', items, actions);
      }),
    );
  }
}
