import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { DropdownConfigQuery } from './dropdown-config.query';

export const GETS_WORKSPACE_CONFIG_QUERY = new InjectionToken<GetsWorkspaceConfigQueryPort>(
  'GETS_WORKSPACE_CONFIG_QUERY',
);

export interface GetsWorkspaceConfigQueryPort {
  getWorkspaceConfig(): Observable<DropdownConfigQuery>;
}
