/* eslint-disable max-lines-per-function */
import { Injectable } from '@angular/core';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HasDataCollection } from '@cobiro/jsonapi';
import { v4 as uuidV4 } from 'uuid';
import { CreatesTeamDtoPort } from '../../../../application/ports/secondary/creates-team.dto-port';
import { CreatesTeamDto } from '../../../../application/ports/secondary/creates-team.dto';

interface TeamAttributes {
  name: string;
  role: string;
  avatar: string;
  counter: number;
  details: {
    avatar: string;
  };
}

@Injectable()
export class HttpTeamsService implements CreatesTeamDtoPort {
  constructor(private readonly _client: GatewayClient) {}

  createTeam(dto: CreatesTeamDto): Observable<string> {
    const body = {
      data: {
        id: uuidV4(),
        type: 'teams',
        attributes: {
          workspaceName: 'Default Workspace',
          companyType: dto.team.companyType,
          numberOfEmployees: dto.team.numberOfEmployees,
          numberOfClients: dto.team.numberOfClients,
          owner: {
            firstName: dto.owner.firstName,
            lastName: dto.owner.lastName,
            companyName: dto.team.companyName,
            country: dto.owner.country,
            phoneNumber: dto.owner.phoneNumber,
          },
        },
      },
    };
    return this._client
      .post<HasDataCollection<TeamAttributes>>('v2/sites/teams', body)
      .pipe(map(() => body.data.id));
  }
}
