import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { DropdownConfigQuery } from './dropdown-config.query';

export const GETS_COMPANY_CONFIG_QUERY = new InjectionToken<GetsCompanyConfigQueryPort>(
  'GETS_COMPANY_CONFIG_QUERY',
);

export interface GetsCompanyConfigQueryPort {
  getCompanyConfig(): Observable<DropdownConfigQuery>;
}
