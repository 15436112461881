import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { CreatesTeamDto } from './creates-team.dto';

export const CREATES_TEAM_DTO_PORT = new InjectionToken<CreatesTeamDtoPort>(
  'CREATES_TEAM_DTO_PORT',
);

export interface CreatesTeamDtoPort {
  createTeam(dto: CreatesTeamDto): Observable<string>;
}
