/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { Injectable } from '@angular/core';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { HasDataCollection } from '@cobiro/jsonapi';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClientSubscriptionDto } from '../../../../application/ports/secondary/dto/clients/clients-subscription.dto';
import { GetsAllClientsSubsciptionsDto } from '../../../../application/ports/secondary/dto/clients/gets-all-clients-subscriptions.dto';
import { GetsAllClientsSubsciptionsDtoPort } from '../../../../application/ports/secondary/dto/clients/gets-all-clients-subscriptions.dto-port';
import { GetsClientSubsciptionsDto } from '../../../../application/ports/secondary/dto/clients/gets-client-subscriptions.dto';
import { GetsClientSubsciptionsDtoPort } from '../../../../application/ports/secondary/dto/clients/gets-client-subscriptions.dto-port';
import { SubscriptionDto } from '../../../../application/ports/secondary/dto/clients/subscription.dto';

export interface ClientProductAttributes {
  isTrial: boolean;
  trialExpiresAt: string;
  productType: 'css' | 'label-manager';
  planType: 'monthly' | 'annual' | 'business';
  subscriptionId: string | null;
}

export interface ClientAttributes {
  products: ClientProductAttributes[];
}

@Injectable()
export class HttpClientsProductService
  implements GetsClientSubsciptionsDtoPort, GetsAllClientsSubsciptionsDtoPort
{
  constructor(private readonly _gatewayClient: GatewayClient) {}
  getAllSubscriptions(dto: GetsAllClientsSubsciptionsDto): Observable<ClientSubscriptionDto[]> {
    return this._gatewayClient
      .get<HasDataCollection<ClientAttributes>>(
        `v1/sites/workspaces/${dto.workspaceId}/clients/products`,
      )
      .pipe(
        map((products: HasDataCollection<ClientAttributes>) => {
          return products.data.map(data => ({
            clientId: data.id,
            subscriptions: data.attributes.products.map(subscriptions => ({
              isTrial: subscriptions.isTrial,
              trialExpiresAt: subscriptions.trialExpiresAt,
              productName: subscriptions.productType,
              planType: subscriptions.planType,
              subscriptionId: subscriptions.subscriptionId,
            })),
          }));
        }),
      );
  }
  getSubscriptions(dto: GetsClientSubsciptionsDto): Observable<SubscriptionDto[]> {
    return this._gatewayClient
      .get<HasDataCollection<ClientProductAttributes>>(
        `v1/sites/workspaces/${dto.workspaceId}/clients/${dto.clientId}/products`,
      )
      .pipe(
        map((products: HasDataCollection<ClientProductAttributes>) => {
          return products.data.map(data => ({
            isTrial: data.attributes.isTrial,
            trialExpiresAt: data.attributes.trialExpiresAt,
            productName: data.attributes.productType,
            planType: data.attributes.planType,
            subscriptionId: data.attributes.subscriptionId,
          }));
        }),
      );
  }
}
