<section
  *ngIf="isUserAdmin$ | async as isUserAdmin"
  [class.flex-column]="isUserAdmin.value"
  class="d-flex flex-md-row align-items-center justify-content-end cs-mv-20 w-100"
>
  <button
    *ngIf="!isUserAdmin.value"
    (click)="goBack()"
    class="cs-btn cs-btn-stroked cs-btn-sm cs-title-8 cs-mh-5 cs-white-space-nowrap"
    type="button"
  >
    <mat-icon svgIcon="chevron-left"></mat-icon>
    {{ '_back' | language }}
  </button>
  <div
    [class.cs-min-width-100-pc-xs]="isUserAdmin.value"
    [class.cs-mb-10]="isUserAdmin.value"
    class="cs-control-wrapper cs-min-width-150-md cs-mb-0-md w-100"
  >
    <mat-icon svgIcon="search" class="cs-input-icon-left"></mat-icon>
    <input
      data-selector="cobiro-pro-users-table-search"
      class="cs-input cs-input-with-icon-left"
      type="text"
      [placeholder]="'_search' | language"
      [formControl]="searchControl"
    />
  </div>
  <div
    *ngIf="isUserAdmin.value"
    class="d-flex w-100 cs-min-width-200-xs cs-max-width-200-sm cs-ml-10 align-self-end"
  >
    <button
      data-selector="lib-pro-invite-user"
      class="cs-btn cs-btn-primary cs-mt-30 cs-mt-0-md w-100"
      (click)="onCreateNewWorkspaceClicked()"
    >
      {{ 'cobiro_pro_workspace_create_new' | language }}
    </button>
  </div>
</section>
