import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export const CREATES_TEAM_QUERY_PORT = new InjectionToken<CreatesTeamQueryPort>(
  'CREATES_TEAM_QUERY_PORT',
);

export interface CreatesTeamQueryPort {
  createTeam(): Observable<void>;
}
