import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import {
  GETS_COMPANY_TYPES_QUERY,
  GetsCompanyTypesQueryPort,
} from '../../../../application/ports/primary/gets-company-types.query-port';
import {
  GETS_NUMBER_OF_CLIENTS_QUERY,
  GetsNumberOfClientsQueryPort,
} from '../../../../application/ports/primary/gets-number-of-clients.query-port';
import {
  GETS_NUMBER_OF_EMPLOYEES_QUERY,
  GetsNumberOfEmployeesQueryPort,
} from '../../../../application/ports/primary/gets-number-of-employees.query-port';
import {
  CREATES_TEAM_QUERY_PORT,
  CreatesTeamQueryPort,
} from '../../../../application/ports/primary/creates-team.query-port';

@Component({
  selector: 'lib-cobiro-pro-create-team',
  templateUrl: './create-team.component.html',
  styleUrls: ['./create-team.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateTeamComponent implements OnDestroy, OnInit {
  readonly form: FormGroup = this._formBuilder.group({
    companyName: ['', Validators.required],
    companyType: ['', Validators.required],
    numberOfEmployees: [0],
    numberOfClients: [0],
  });

  readonly companyTypes$ = this._getsCompanyTypesQuery.getAvailableCompanyTypes();
  readonly numberOfClients$ = this._getsNumberOfClientsQuery.getAvailableNumberOfClients();
  readonly numberOfEmployees$ = this._getsNumberOfEmployeesQuery.getAvailableNumberOfEmployees();
  private readonly _destroyed$ = new Subject<void>();

  showNumberOfEmployees = true;

  constructor(
    @Inject(GETS_COMPANY_TYPES_QUERY)
    private readonly _getsCompanyTypesQuery: GetsCompanyTypesQueryPort,
    @Inject(GETS_NUMBER_OF_CLIENTS_QUERY)
    private readonly _getsNumberOfClientsQuery: GetsNumberOfClientsQueryPort,
    @Inject(GETS_NUMBER_OF_EMPLOYEES_QUERY)
    private readonly _getsNumberOfEmployeesQuery: GetsNumberOfEmployeesQueryPort,
    private readonly _formBuilder: FormBuilder,
  ) {}
  ngOnInit(): void {
    this.form
      .get('companyType')
      .valueChanges.pipe(takeUntil(this._destroyed$))
      .subscribe((value: string) => {
        this.showNumberOfEmployees = value !== 'individual';
        this.form.get('numberOfEmployees').setValue(value !== 'individual' ? null : 1);
      });
  }

  hasError(fieldName: string): boolean {
    const control = this.form.get(fieldName);
    return control.touched && !!control.errors;
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
