import { NgModule } from '@angular/core';
import { provideApplicationEventsHandler } from '@cobiro/eda';
import { ClientsListChangedEventHandler } from '../adapters/primary/handlers/clients-list-changed.event-handler';
import { LoadSelectedShopResolver } from '../adapters/primary/resolvers/load-selected-shop.resolver';
import { GetTeamSettingsFormErrorServiceModule } from '../adapters/secondary/infrastucture/get-team-settings-form-error/get-team-settings-form-error.service-module';
import { HttpChargebeeConfirmPaymentServiceModule } from '../adapters/secondary/infrastucture/http-chargebee-confirm-payment/http-chargebee-confirm-payment.service-module';
import { HttpChecksDiscountCodeServiceModule } from '../adapters/secondary/infrastucture/http-checks-discount-code/http-checks-discount-code.service-module';
import { HttpClientSitesServiceModule } from '../adapters/secondary/infrastucture/http-client-sites/http-client-sites.service-module';
import { HttpClientsProductServiceModule } from '../adapters/secondary/infrastucture/http-clients-products/http-clients-products.service-module';
import { HttpClientsServiceModule } from '../adapters/secondary/infrastucture/http-clients/http-clients.service-module';
import { HttpGetsBillingDataServiceModule } from '../adapters/secondary/infrastucture/http-gets-billing-data/http-gets-billing-data.service-module';
import { HttpGetsCountriesServiceModule } from '../adapters/secondary/infrastucture/http-gets-countries/http-gets-countries.service-module';
import { HttpGetsPaymentPlanServiceModule } from '../adapters/secondary/infrastucture/http-gets-payment-plan/http-gets-payment-plan.service-module';
import { HttpGetsPaymentSourcesServiceModule } from '../adapters/secondary/infrastucture/http-gets-payment-sources/http-gets-payment-sources.service-module';
import { HttpGetsPlanEstimateServiceModule } from '../adapters/secondary/infrastucture/http-gets-plan-estimate/http-gets-plan-estimate.service-module';
import { HttpManagesPlanServiceModule } from '../adapters/secondary/infrastucture/http-manages-plan/http-manages-plan.service-module';
import { HttpTeamCreatePaymentIntentServiceModule } from '../adapters/secondary/infrastucture/http-team-create-payment-intent/http-team-create-payment-intent.service-module';
import { HttpTeamCreatePaymentSourceServiceModule } from '../adapters/secondary/infrastucture/http-team-create-payment-source/http-team-create-payment-source.service-module';
import { HttpTeamSettingsServiceModule } from '../adapters/secondary/infrastucture/http-team-settings/http-team-settings.service-module';
import { InMemoryClientsContextStorageModule } from '../adapters/secondary/infrastucture/in-memory-clients-context/in-memory-clients-context.storage-module';
import { ClientsStateModule } from '../application/state/client/clients.state-module';
import { ClientsListStateModule } from '../application/state/clients-list/clients-list.state-module';
import { LatestClientsListStateModule } from '../application/state/latest-clients-list/latest-clients-list.state-module';
import { PaymentsStateModule } from '../application/state/payments/payments.state-module';
import { TeamSettingsStateModule } from '../application/state/team-settings/team-settings.state-module';
import { HttpCancelsClientSubscriptionCSSServiceModule } from '../adapters/secondary/infrastucture/http-cancels-client-subscription-css/http-cancels-client-subscription.service-module';
import { HttpCancelsClientSubscriptionLMServiceModule } from '../adapters/secondary/infrastucture/http-cancels-client-subscription-lm/http-cancels-client-subscription-lm.service-module';
import { SetupFlowStateModule } from '../application/state/setup-flow/setup-flow.state-module';
import { HasProductSelectedGuard } from '../adapters/primary/guards/has-product-selected.guard';
import { InMemoryProductServiceModule } from '../adapters/secondary/infrastucture/in-memory-product/in-memory-product.service-module';
import { PaymentFlowStartedEventHandler } from '../adapters/primary/handlers/payment-flow-started.event-handler';
import { HttpTrialAvailabilityServiceModule } from '../adapters/secondary/infrastucture/http-trial-availability/http-trial-availability.service-module';
@NgModule({
  imports: [
    ClientsStateModule,
    LatestClientsListStateModule,
    ClientsListStateModule,
    PaymentsStateModule,
    TeamSettingsStateModule,
    SetupFlowStateModule,
    HttpClientsServiceModule,
    InMemoryClientsContextStorageModule,
    HttpCancelsClientSubscriptionCSSServiceModule,
    HttpCancelsClientSubscriptionLMServiceModule,
    HttpClientSitesServiceModule,
    HttpGetsPaymentPlanServiceModule,
    HttpGetsPaymentSourcesServiceModule,
    HttpGetsPlanEstimateServiceModule,
    HttpChecksDiscountCodeServiceModule,
    HttpGetsBillingDataServiceModule,
    HttpManagesPlanServiceModule,
    HttpGetsCountriesServiceModule,
    HttpTeamSettingsServiceModule,
    GetTeamSettingsFormErrorServiceModule,
    HttpTeamCreatePaymentSourceServiceModule,
    HttpTeamCreatePaymentIntentServiceModule,
    HttpChargebeeConfirmPaymentServiceModule,
    HttpClientsProductServiceModule,
    InMemoryProductServiceModule,
    HttpTrialAvailabilityServiceModule,
  ],
  providers: [
    HasProductSelectedGuard,
    LoadSelectedShopResolver,
    provideApplicationEventsHandler(ClientsListChangedEventHandler),
    provideApplicationEventsHandler(PaymentFlowStartedEventHandler),
  ],
})
export class CobiroProRewriteClientProvidersModule {}
