import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CobiroProContextChangedEvent } from '@app.cobiro.com/core/events';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { ClearsContextCommandPort } from '../ports/primary/clears-context.command-port';
import { CobiroProContextQuery } from '../ports/primary/cobiro-pro-context.query';
import { DisablesProModeCommandPort } from '../ports/primary/disables-pro-mode.command-port';
import { EnablesProModeCommandPort } from '../ports/primary/enables-pro-mode.command-port';
import { FetchesCobiroProContextCommandPort } from '../ports/primary/fetches-cobiro-pro-context.command-port';
import { GetsCobiroProContextQueryPort } from '../ports/primary/gets-cobiro-pro-context.query-port';
import { GoesToPersonalModeCommandPort } from '../ports/primary/goes-to-personal-mode.command-port';
import { NavigateToSelectedTeamHomeCommandPort } from '../ports/primary/navigate-to-selected-team-home.command-port';
import { SetsLatestCobiroProLoginCommandPort } from '../ports/primary/sets-latest-cobiro-pro-login.command-port';
import { SetsSelectedTeamCommandPort } from '../ports/primary/sets-selected-team.command-port';
import { SetsSelectedWorkspaceCommandPort } from '../ports/primary/sets-selected-workspace.command-port';
import { VerifyLatestLoginCommandPort } from '../ports/primary/verify-latest-login.command-port';
import { CobiroProContextDto } from '../ports/secondary/cobiro-pro-context.dto';
import {
  COBIRO_PRO_LOGOUT_DISPATCHER,
  CobiroProLogoutDispatcherPort,
} from '../ports/secondary/cobiro-pro-logout.dispatcher-port';
import {
  CONTEXT_CHANGED_DISPATCHER,
  ContextChangedDispatcherPort,
} from '../ports/secondary/context-changed.dispatcher-port';
import {
  DELETE_LATEST_COBIRO_PRO_LOGIN_PORT,
  DeleteLatestCobiroProLoginDtoPort,
} from '../ports/secondary/delete-latest-cobiro-pro-login.dto-port';
import {
  GETS_COBIRO_PRO_CONTEXT_DTO,
  GetsCobiroProContextDtoPort,
} from '../ports/secondary/gets-cobiro-pro-context.dto-port';
import {
  GET_LATEST_COBIRO_PRO_LOGIN_DTO,
  GetsLatestCobiroProLoginDtoPort,
} from '../ports/secondary/gets-latest-cobiro-pro-login.dto-port';
import { LatestCobiroProLoginDto } from '../ports/secondary/latest-cobiro-pro-login.dto';
import {
  SAVES_COBIRO_PRO_CONTEXT_DTO,
  SavesCobiroProContextDtoPort,
} from '../ports/secondary/saves-cobiro-pro-context.dto-port';
import {
  SAVE_LATEST_COBIRO_PRO_LOGIN_DTO,
  SavesLatestCobiroProLoginDtoPort,
} from '../ports/secondary/saves-latest-cobiro-pro-login.dto-port';

@Injectable()
export class ContextState
  implements
    GetsCobiroProContextQueryPort,
    EnablesProModeCommandPort,
    DisablesProModeCommandPort,
    GoesToPersonalModeCommandPort,
    SetsSelectedTeamCommandPort,
    SetsSelectedWorkspaceCommandPort,
    FetchesCobiroProContextCommandPort,
    NavigateToSelectedTeamHomeCommandPort,
    ClearsContextCommandPort,
    SetsLatestCobiroProLoginCommandPort,
    VerifyLatestLoginCommandPort
{
  private readonly _context$ = new ReplaySubject<CobiroProContextDto>(1);

  constructor(
    @Inject(GETS_COBIRO_PRO_CONTEXT_DTO)
    private readonly _getsCobiroProContextDto: GetsCobiroProContextDtoPort,
    @Inject(SAVES_COBIRO_PRO_CONTEXT_DTO)
    private readonly _savesCobiroProContextDto: SavesCobiroProContextDtoPort,
    @Inject(CONTEXT_CHANGED_DISPATCHER)
    private readonly _contextChangedDispatcher: ContextChangedDispatcherPort,
    @Inject(COBIRO_PRO_LOGOUT_DISPATCHER)
    private readonly _cobiroProLogoutDispatcher: CobiroProLogoutDispatcherPort,
    @Inject(SAVE_LATEST_COBIRO_PRO_LOGIN_DTO)
    private readonly _savesLatestCobiroProLoginDtoPort: SavesLatestCobiroProLoginDtoPort,
    @Inject(GET_LATEST_COBIRO_PRO_LOGIN_DTO)
    private readonly _getsLatestCobiroProLoginDtoPort: GetsLatestCobiroProLoginDtoPort,
    @Inject(DELETE_LATEST_COBIRO_PRO_LOGIN_PORT)
    private readonly _deleteLatestCobiroProLoginDtoPort: DeleteLatestCobiroProLoginDtoPort,
    private readonly _router: Router,
  ) {
    this.fetchContext().pipe(take(1)).subscribe();
  }

  getContext(): Observable<CobiroProContextQuery> {
    return this._context$.asObservable().pipe(map(dto => CobiroProContextQuery.fromDto(dto)));
  }

  enableProMode(): Observable<boolean> {
    return this._toggleProMode(true);
  }

  disableProMode(): Observable<boolean> {
    return this._toggleProMode(false);
  }

  goToPersonalMode(): Observable<boolean> {
    return this._toggleProMode(false).pipe(
      tap(() => {
        this._cobiroProLogoutDispatcher.dispatch();
      }),
    );
  }

  setSelectedWorkspace(
    workspaceId: string | null,
    workspaceName: string | null,
    avatar: string | null,
    membersCount: number | null,
  ): Observable<boolean> {
    return this._context$.pipe(
      take(1),
      switchMap(context =>
        this._handleContextChange(context, {
          selectedWorkspace: { id: workspaceId, name: workspaceName, avatar, membersCount },
        }),
      ),
    );
  }

  setSelectedTeam(
    teamId: string,
    teamName: string,
    userRole: string,
    avatar: string | null,
  ): Observable<boolean> {
    return this._context$.pipe(
      take(1),
      switchMap(context =>
        this._handleContextChange(context, {
          selectedTeam: { id: teamId, name: teamName, userRole, avatar },
        }),
      ),
    );
  }

  fetchContext(): Observable<boolean> {
    return this._getsCobiroProContextDto.getContext().pipe(
      take(1),
      tap(context => {
        this._context$.next(context);
        this._dispatchContextChangeEvent(context);
      }),
      map(() => true),
    );
  }

  navigateHome(): void {
    this._context$.pipe(take(1)).subscribe(context => {
      this._router.navigate(['connect', context.selectedTeam.id, 'home']);
    });
  }

  clearContextAndGoToPersonalMode(): void {
    this.clearContext();
    this._cobiroProLogoutDispatcher.dispatch();
  }

  clearContext(): void {
    const clearedContext = {
      proModeEnabled: false,
      selectedTeam: {
        id: null,
        avatar: null,
        name: null,
        userRole: null,
      },
      selectedWorkspace: {
        id: null,
        avatar: null,
        name: null,
        membersCount: null,
      },
    };
    this._handleContextChange({} as CobiroProContextDto, clearedContext);
  }

  private _toggleProMode(shouldBeEnabled: boolean): Observable<boolean> {
    return this._context$.pipe(
      take(1),
      switchMap(context => {
        if (context.proModeEnabled !== shouldBeEnabled) {
          return this._handleContextChange(context, {
            proModeEnabled: shouldBeEnabled,
          });
        } else {
          return of(true);
        }
      }),
    );
  }

  private _handleContextChange(context: CobiroProContextDto, change: Partial<CobiroProContextDto>) {
    const newContext: CobiroProContextDto = { ...context, ...change };
    this._context$.next(newContext);
    this._dispatchContextChangeEvent(newContext);
    return this._savesCobiroProContextDto.saveContext(newContext);
  }

  setLatestLogin(userId: string): void {
    this._context$
      .pipe(
        take(1),
        tap((context: CobiroProContextDto) => {
          if (context.proModeEnabled) {
            this._savesLatestCobiroProLoginDtoPort.saveLatestCobiroProLogin({
              userId,
              team: {
                id: context.selectedTeam.id,
                avatar: context.selectedTeam.avatar || null,
                name: context.selectedTeam.name,
                role: context.selectedTeam.userRole,
              },
              workspace: {
                id: context.selectedWorkspace.id || null,
                avatar: context.selectedWorkspace.avatar || null,
                name: context.selectedWorkspace.name || null,
                membersCount: context.selectedWorkspace.membersCount,
              },
            });
          }
        }),
      )
      .subscribe();
  }

  verifyLatestLogin(userId: string): void {
    const latestLoginDto: LatestCobiroProLoginDto | null =
      this._getsLatestCobiroProLoginDtoPort.getLatestCobiroProLogin();
    if (!latestLoginDto) {
      this._router.navigate(['connect']);
      return;
    }

    const teamId = latestLoginDto.team?.id;
    if (latestLoginDto.userId === userId && teamId) {
      combineLatest(
        this.setSelectedTeam(
          teamId,
          latestLoginDto.team.name,
          latestLoginDto.team.role,
          latestLoginDto.team.avatar,
        ),
        this.setSelectedWorkspace(
          latestLoginDto.workspace.id,
          latestLoginDto.workspace.name,
          latestLoginDto.workspace.avatar,
          latestLoginDto.workspace.membersCount,
        ),
      ).subscribe(() => {
        this._router.navigate(['connect', teamId, 'home']);
      });
    } else {
      this._router.navigate(['connect']);
    }
    this._deleteLatestCobiroProLoginDtoPort.deleteLatestLogin();
  }

  private _dispatchContextChangeEvent(context: CobiroProContextDto): void {
    // TODO WJ - why context elements can be null?
    this._contextChangedDispatcher.dispatch(
      new CobiroProContextChangedEvent({
        proModeEnabled: context.proModeEnabled,
        selectedTeam: {
          id: context.selectedTeam?.id,
          name: context.selectedTeam?.name,
          userRole: context.selectedTeam?.userRole,
          avatar: context.selectedTeam?.avatar,
        },
        selectedWorkspace: {
          id: context.selectedWorkspace?.id,
          name: context.selectedWorkspace?.name,
          avatar: context.selectedWorkspace?.avatar,
          membersCount: context.selectedWorkspace?.membersCount,
        },
      }),
    );
  }
}
