/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { Inject, Injectable } from '@angular/core';
import {
  TeamIdGetter,
  TEAM_ID_GETTER,
  WorkspaceIdGetter,
  WORKSPACE_ID_GETTER,
} from '@app.cobiro.com/cobiro-pro/context';
import { CobiroProWorkspaceSwitchedEvent } from '@app.cobiro.com/core/events';
import { FrontendPagination, PaginationQuery, SORT_ORDER } from '@app.cobiro.com/core/pagination';
import { ApplicationBus, APPLICATION_BUS } from '@cobiro/eda';
import { combineLatest, map, Observable, of, switchMap, tap } from 'rxjs';
import { ClientListItemQuery } from '../../ports/primary/clients/client-list-item.query';
import { SetsClientsListSortCommand } from '../../ports/primary/clients/sets-clients-list-sort.command';
import {
  SELECTS_CLIENTS_CONTEXT_STORAGE,
  SelectsClientsContextStoragePort,
} from '../../ports/secondary/context/selects-clients-context.storage-port';
import {
  CLIENTS_LIST_CHANGED_DISPATCHER,
  ClientsListChangedDispatcherPort,
} from '../../ports/secondary/dispatchers/clients-list-changed.dispatcher-port';
import { ClientDTO } from '../../ports/secondary/dto/clients/client.dto';
import { ClientSubscriptionDto } from '../../ports/secondary/dto/clients/clients-subscription.dto';
import {
  GetsAllClientsSubsciptionsDtoPort,
  GETS_ALL_CLIENTS_SUBSCRIPTIONS_DTO_PORT,
} from '../../ports/secondary/dto/clients/gets-all-clients-subscriptions.dto-port';
import { GetsAllTeamClientsListPaginationQueryPort } from '../../ports/primary/clients/gets-all-team-clients-list-pagination.query-port';
import { SetsAllTeamClientsListSortCommandPort } from '../../ports/primary/clients/sets-all-team-clients-list-sort.command-port';
import { LoadAllTeamClientsCommandPort } from '../../ports/primary/clients/load-all-team-clients.command-port';
import { GetsAllTeamClientsQueryPort } from '../../ports/primary/clients/gets-all-team-clients.query-port';
import {
  GETS_ALL_TEAM_CLIENT_DTO,
  GetsAllTeamClientDtoPort,
} from '../../ports/secondary/dto/clients/gets-all-team-client.dto-port';
import { SetsAllTeamClientsSearchPhraseCommandPort } from '../../ports/primary/clients/sets-all-team-clients-search-phrase.command-port';
import { SetsSearchPhraseCommand } from '../../ports/primary/clients/sets-search-phrase.command';
import { SetsAllTeamClientsListPageCommandPort } from '../../ports/primary/clients/sets-all-team-clients-list-page.command-port';
import { ResetsAllTeamClientsListPaginationCommandPort } from '../../ports/primary/clients/resets-all-team-clients-list-pagination.command-port';
import {
  GETS_ALL_CLIENTS_SUBSCRIPTIONS_BY_TEAM_DTO_PORT,
  GetsAllClientsSubsciptionsByTeamDtoPort,
} from '../../ports/secondary/dto/clients/gets-all-clients-subscriptions-by-team.dto-port';
import {
  PATCHES_CLIENTS_CONTEXT_STORAGE,
  PatchesClientsContextStoragePort,
} from '../../ports/secondary/context/patches-clients-context.storage-port';

@Injectable()
export class AllTeamClientsListState
  implements
    GetsAllTeamClientsQueryPort,
    GetsAllTeamClientsListPaginationQueryPort,
    LoadAllTeamClientsCommandPort,
    SetsAllTeamClientsListSortCommandPort,
    SetsAllTeamClientsSearchPhraseCommandPort,
    SetsAllTeamClientsListPageCommandPort,
    ResetsAllTeamClientsListPaginationCommandPort
{
  private readonly _pagination: FrontendPagination<ClientDTO> = FrontendPagination.fromRaw(
    this._selectsClientsContextStorage
      .select()
      .pipe(map(clientsContext => clientsContext.all.filter(clientList => !clientList.archived))),
    0,
    20,
    ['createdAt', SORT_ORDER.DESC],
    {},
  );

  constructor(
    @Inject(GETS_ALL_TEAM_CLIENT_DTO)
    private readonly _getAllClientDTO: GetsAllTeamClientDtoPort,
    @Inject(SELECTS_CLIENTS_CONTEXT_STORAGE)
    private readonly _selectsClientsContextStorage: SelectsClientsContextStoragePort,
    @Inject(PATCHES_CLIENTS_CONTEXT_STORAGE)
    private readonly _patchesClientsContextStorage: PatchesClientsContextStoragePort,
    @Inject(TEAM_ID_GETTER) private readonly _teamIdGetter: TeamIdGetter,
    @Inject(GETS_ALL_CLIENTS_SUBSCRIPTIONS_BY_TEAM_DTO_PORT)
    private readonly _getsAllClientsSubsciptionsDtoPort: GetsAllClientsSubsciptionsByTeamDtoPort,
  ) {}
  getsPaginatedClients(): Observable<ClientListItemQuery[]> {
    return this._pagination.getPaginatedList().pipe(
      map(clients => {
        return this._mapClientsDtoToListItemQuery(clients);
      }),
    );
  }

  resetClientListPagination(): void {
    this._pagination.reset();
  }

  getTotalClients(): Observable<number> {
    return this._pagination.total;
  }

  setClientsListPage(pageIndex: number): void {
    this._pagination.setPage(pageIndex);
  }

  setSearchPhrase(command: SetsSearchPhraseCommand): Observable<void> {
    this._pagination.setPage(0);
    this._pagination.setSearch(command.phrase);
    return of(void 0);
  }

  getAllTeamClientsQuery(): Observable<ClientListItemQuery[]> {
    return this._pagination.getPaginatedList().pipe(
      map(clients => {
        return this._mapClientsDtoToListItemQuery(clients);
      }),
    );
  }

  getAllTeamClientListPagination(): Observable<PaginationQuery> {
    return this._pagination.getPaginationQuery();
  }

  setSort(command: SetsClientsListSortCommand): void {
    this._pagination.setPage(0);
    this._pagination.setSorting(
      command.key as keyof ClientDTO,
      command.direction.toUpperCase() as SORT_ORDER,
    );
  }

  loadAllClients(): Observable<ClientDTO[]> {
    const teamId = this._teamIdGetter.getTeamId();
    return combineLatest([
      this._getAllClientDTO.getAllTeamClients({ teamId: teamId }),
      this._getsAllClientsSubsciptionsDtoPort.getAllSubscriptionsByTeam({ teamId: teamId }),
    ]).pipe(
      switchMap(([clients, clientSubscriptions]: [ClientDTO[], ClientSubscriptionDto[]]) => {
        const clientList = clients.map(client => {
          const subscriptions = clientSubscriptions.find(dto => dto.clientId === client.id)
            ? clientSubscriptions.find(dto => dto.clientId === client.id).subscriptions
            : [];
          return {
            ...client,
            ...{
              subscriptions: subscriptions,
              productStatuses: {
                css: client.cssIntegrationStatus,
                'label-manager': subscriptions?.find(subs => subs.productName === 'label-manager')
                  ? ('active' as const)
                  : null,
              },
            },
          };
        });
        return this._patchesClientsContextStorage
          .patch({ all: clientList })
          .pipe(map(() => clientList));
      }),
    );
  }

  private _mapClientsDtoToListItemQuery(clients: ClientDTO[]): ClientListItemQuery[] {
    return clients.map(clientDto => ClientListItemQuery.fromClientDTO(clientDto));
  }
}
