import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { NgModuleWithProviders } from 'ng-mocks';
import { GETS_USER_DETAILS_QUERY } from '../../../../application/ports/primary/gets-user-details.query-port';
import { GETS_USER_MENU_OPTIONS_QUERY } from '../../../../application/ports/primary/gets-user-menu-options.query-port';
import { CobiroProUserMenuComponent } from './user-menu.component';
import { HuiAvatarModule } from '@app.cobiro.com/shared/hui/avatar';

@NgModule({
  imports: [
    CommonModule,
    HuiAvatarModule,
    LanguageModule,
    MatIconModule,
    MatMenuModule,
    RouterModule,
  ],
  declarations: [CobiroProUserMenuComponent],
  exports: [CobiroProUserMenuComponent],
})
export class CobiroProUserMenuComponentModule {
  static forRoot(
    getsUserDetailsAdapter: object,
    getsUserMenuOptionsAdapter: object,
  ): NgModuleWithProviders<CobiroProUserMenuComponentModule> {
    return {
      ngModule: CobiroProUserMenuComponentModule,
      providers: [
        {
          provide: GETS_USER_DETAILS_QUERY,
          useExisting: getsUserDetailsAdapter,
        },
        {
          provide: GETS_USER_MENU_OPTIONS_QUERY,
          useExisting: getsUserMenuOptionsAdapter,
        },
      ],
    };
  }
}
