<ng-container
  *huiLoaderContainer="{
    workspaces: workspaces$ | async,
    isUserAdmin: isUserAdmin$ | async
  } as tableConfig"
>
  <div class="d-flex cs-position-relative-xs table-responsive-container">
    <table
      mat-table
      [dataSource]="tableConfig.workspaces"
      class="cs-mat-table highlight-row cs-min-width-100-xs cs-position-relative-xs"
      data-selector="cobiro-pro-workspaces-table"
    >
      <ng-container matColumnDef="cobiro_pro_workspaces_table_workspaces_name" sticky>
        <th mat-header-cell *matHeaderCellDef>
          {{ 'cobiro_pro_workspace_column_workspace' | language }}
        </th>
        <td
          mat-cell
          *matCellDef="let workspace"
          data-selector="cobiro-pro-workspaces-table-workspace-name"
        >
          <div class="d-flex align-items-center">
            <a class="cs-link" (click)="goToWorkspace(workspace)">{{ workspace.name }}</a>
            <button
              *ngIf="tableConfig.isUserAdmin.value"
              (click)="onEditButtonClicked(workspace)"
              class="cs-btn cs-btn-sm cs-btn-ghost"
              type="button"
            >
              <mat-icon class="cs-size-sm" svgIcon="pencil"></mat-icon>
            </button>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="cobiro_pro_workspaces_table_users">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'cobiro_pro_workspace_column_users' | language }}
        </th>
        <td
          class="text-left cs-white-space-nowrap"
          mat-cell
          *matCellDef="let workspace"
          data-selector="cobiro-pro-workspaces-table-users"
        >
          {{
            workspace.membersCount +
              ' ' +
              ((workspace.membersCount === 1 ? '_user' : '_users') | language)
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="cobiro_pro_workspaces_table_shops">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'cobiro_pro_workspace_column_shops' | language }}
        </th>
        <td
          class="text-left cs-white-space-nowrap"
          mat-cell
          *matCellDef="let workspace"
          data-selector="cobiro-pro-workspaces-table-shops"
        >
          {{
            workspace.clientCount +
              ' ' +
              ((workspace.clientCount === 1 ? '_shop' : '_shops') | language)
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="cobiro_pro_workspaces_table_created_at">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'cobiro_pro_workspace_column_created_at' | language }}
        </th>
        <td
          class="text-left cs-white-space-nowrap"
          mat-cell
          *matCellDef="let workspace"
          data-selector="cobiro-pro-workspaces-table-created-at"
        >
          {{ workspace.createdAt | date : 'dd.MM.YY HH:mm' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="cobiro_pro_workspaces_table_edit">
        <th mat-header-cell *matHeaderCellDef class="cs-width-120-xs pr-2 text-center">
          {{ 'cobiro_pro_workspace_column_view' | language }}
        </th>
        <td
          mat-cell
          *matCellDef="let workspace"
          class="cs-width-120-xs px-0"
          data-selector="cobiro-pro-workspaces-table-view"
        >
          <div class="d-flex justify-content-center align-items-center">
            <button
              class="cs-btn cs-btn-sm cs-btn-stroked cs-white-space-nowrap cs-mh-20"
              (click)="goToWorkspaceUsers(workspace.id)"
              type="button"
            >
              {{ 'cobiro_pro_workspace_column_view_users' | language }}
            </button>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="cobiro_pro_workspaces_table_delete">
        <th mat-header-cell *matHeaderCellDef class="cs-width-120-xs pr-2 text-center">
          {{ 'cobiro_pro_workspace_column_delete' | language }}
        </th>
        <td
          mat-cell
          *matCellDef="let workspace"
          class="cs-width-120-xs px-0"
          data-selector="cobiro-pro-workspaces-table-delete"
        >
          <div class="d-flex justify-content-center align-items-center">
            <button
              (click)="onDeleteButtonClicked(workspace)"
              class="cs-btn cs-btn-sm cs-btn-error cs-white-space-nowrap cs-mh-20"
              type="button"
            >
              {{ 'cobiro_pro_workspace_column_delete' | language }}
              <mat-icon svgIcon="trash"></mat-icon>
            </button>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr mat-row class="cs-bb-1 cs-b-shade-10" *matRowDef="let row; columns: columns"></tr>
    </table>
  </div>
</ng-container>
