/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { Inject, Injectable } from '@angular/core';
import { CobiroProWorkspaceSwitchedEvent } from '@app.cobiro.com/core/events';
import {
  SetsSelectedWorkspaceCommandPort,
  SETS_SELECTED_WORKSPACE_COMMAND,
  TeamIdGetter,
  TEAM_ID_GETTER,
} from '@app.cobiro.com/cobiro-pro/context';
import { take } from 'rxjs';
import { STORAGE } from '@app.cobiro.com/core/storage';
import {
  GETS_WORKSPACES_QUERY,
  GetsWorkspacesQuery,
} from '../../../application/ports/primary/gets-workspaces.query';

@Injectable()
export class CobiroProWorkspaceSwitchedEventHandler implements ApplicationEventsHandler {
  eventsClasses = [CobiroProWorkspaceSwitchedEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(
    @Inject(STORAGE)
    private readonly _storage: Storage,
    @Inject(TEAM_ID_GETTER)
    private readonly _teamIdGetter: TeamIdGetter,
    @Inject(SETS_SELECTED_WORKSPACE_COMMAND)
    private readonly _setsSelectedWorkspaceCommandPort: SetsSelectedWorkspaceCommandPort,
    @Inject(GETS_WORKSPACES_QUERY)
    private readonly _GetsWorkspacesQuery: GetsWorkspacesQuery,
  ) {}

  handle([event]: [CobiroProWorkspaceSwitchedEvent]): void {
    const recentlyViewedWorkspace = this._storage.getItem('cobiro-pro-recently-viewed-workspaces')
      ? JSON.parse(this._storage.getItem('cobiro-pro-recently-viewed-workspaces'))
      : {};
    if (recentlyViewedWorkspace[this._teamIdGetter.getTeamId()]) {
      const deleteIfExist = recentlyViewedWorkspace[this._teamIdGetter.getTeamId()].filter(
        (e: string) => e !== event.workspaceId,
      );
      deleteIfExist.unshift(event.workspaceId);

      recentlyViewedWorkspace[this._teamIdGetter.getTeamId()] = deleteIfExist.slice(0, 5);
    } else {
      recentlyViewedWorkspace[this._teamIdGetter.getTeamId()] = [event.workspaceId];
    }
    this._storage.setItem(
      'cobiro-pro-recently-viewed-workspaces',
      JSON.stringify(recentlyViewedWorkspace),
    );

    this._setsSelectedWorkspaceCommandPort
      .setSelectedWorkspace(
        event.workspaceId,
        event.workspaceName,
        event.avatar,
        event.membersCount,
      )
      .pipe(take(1))
      .subscribe();
  }
}
