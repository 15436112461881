/* eslint-disable max-lines-per-function */
import { Inject, Injectable } from '@angular/core';
import {
  GetsSideBarOptionsQueryPort,
  SideBarOptionQuery,
} from '@app.cobiro.com/cobiro-pro-rewrite/layout';
import { combineLatest, Observable } from 'rxjs';
import {
  GETS_COBIRO_PRO_CONTEXT_QUERY,
  GetsCobiroProContextQueryPort,
  CobiroProContextQuery,
} from '@app.cobiro.com/cobiro-pro/context';
import { map } from 'rxjs/operators';
import { UserDetailsQuery } from '@app.cobiro.com/cobiro-pro-rewrite/layout';
import { HasData } from '@cobiro/jsonapi';

export interface UserDetailsAttributes {
  email: string;
  partnerId: number;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  country: string;
  group: string;
  createdAt: number;
  avatarUrl: string;
}

@Injectable()
export class GetsSideBarOptionsService implements GetsSideBarOptionsQueryPort {
  constructor(
    @Inject(GETS_COBIRO_PRO_CONTEXT_QUERY)
    private readonly _getsCobiroProContextQuery: GetsCobiroProContextQueryPort,
  ) {}

  getSideBarOptions(user: UserDetailsQuery): Observable<SideBarOptionQuery[]> {
    return this._getsCobiroProContextQuery.getContext().pipe(
      map((context: CobiroProContextQuery) => [
        {
          icon: 'home',
          type: 'internal' as const,
          name: 'cobiro_pro_home',
          selector: 'home',
          path: `/connect/${context.selectedTeamId}/home`,
          exactMatch: false,
        },
        {
          icon: 'briefcase',
          type: 'internal' as const,
          name: 'cobiro_pro_shops',
          selector: 'clients',
          path: `/connect/${context.selectedTeamId}/shops`,
          exactMatch: true,
        },
        {
          icon: 'users',
          type: 'internal' as const,
          name: 'cobiro_pro_users',
          selector: 'members',
          path: `/connect/${context.selectedTeamId}/members`,
          exactMatch: true,
        },
        {
          icon: 'wallet',
          type: 'internal' as const,
          name: 'cobiro_pro_billing_and_payment',
          selector: 'billing_and_payment',
          path: `/connect/${context.selectedTeamId}/settings`,
          exactMatch: false,
        },
        ...(context.selectedTeamUserRole !== 'basic_user'
          ? [
              {
                icon: 'settings',
                type: 'internal' as const,
                name: 'cobiro_pro_company_settings',
                selector: 'company_settings',
                path: `/connect/${context.selectedTeamId}/company-settings/members`,
                exactMatch: false,
                divider: true,
              },
              {
                icon: 'cashback',
                type: 'external' as const,
                name: 'cobiro_pro_partner_program',
                selector: 'partner_program',
                path: `https://cobiro.com/partners/?your-name=${user.displayName}&your-company=${context.selectedTeamName}&your-email=${user.email}&your-telephone=${user.phoneNumber}&team-id=${context.selectedTeamId}`,
              },
            ]
          : []),
        {
          icon: 'tooltip-question-mark',
          type: 'internal' as const,
          name: 'cobiro_pro_faq',
          selector: 'faq',
          path: `/connect/${context.selectedTeamId}/faq`,
          featureFlag: 'cobiro_pro_css_faq',
          exactMatch: false,
        },
      ]),
    );
  }
}
