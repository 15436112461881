import { TeamQuery } from '../../ports/primary/team.query';
import { WorkspaceQuery } from './workspace.query';

export enum ItemAction {
  CREATE = 'create',
}

export class DropdownItemsQuery {
  constructor(
    public readonly routerLink: string[],
    public readonly title: string,
    public readonly itemObj: TeamQuery | WorkspaceQuery,
    public readonly selected: boolean,
  ) {}
}

class DropdownActionsQuery {
  constructor(
    public readonly translationKey: string,
    public readonly icon: string,
    public readonly action?: ItemAction,
    public readonly routerLink?: string,
  ) {}
}

export class DropdownConfigQuery {
  constructor(
    public readonly title: string,
    public readonly subtitle: string,
    public readonly items: DropdownItemsQuery[],
    public readonly actions: DropdownActionsQuery[],
  ) {}
}
