/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { Inject, Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  catchError,
  combineLatest,
  map,
  of,
  repeat,
  skipWhile,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { LabelManagerNonCssIntegrationStepperComponent } from '../../../adapters/primary/ui/modals/label-manager-non-css-integration/label-manager-non-css-integration-stepper/label-manager-non-css-integration-stepper.component';
import { InitsNonCssIntegrationCommand } from '../../ports/primary/inits-non-css-integration.command';
import {
  CREATES_NON_CSS_INTEGRATION_DTO,
  CreatesNonCssIntegrationDtoPort,
} from '../../ports/secondary/creates-non-css-integration.dto-port';
import { IntegrationIdChangedEvent } from '@app.cobiro.com/cobiro-pro-rewrite/cobiro-css';
import { CreatesNonCssIntegrationQuery } from '../../ports/primary/creates-non-css-integration.query';
import { CreatesNonCssIntegrationQueryPort } from '../../ports/primary/creates-non-css-integration.query-port';
import { InitsNonCssIntegrationCommandPort } from '../../ports/primary/inits-non-css-integration.command-port';
import { HuiAlert } from '@app.cobiro.com/shared/hui/alert';
import { APPLICATION_BUS, ApplicationBus } from '@cobiro/eda';
import { STORAGE } from '@app.cobiro.com/core/storage';
import {
  GETS_INTEGRATION_DTO,
  GetsIntegrationDtoPort,
} from '../../ports/secondary/gets-integration.dto-port';
import { ENV_CONFIG, GetsConfig } from '@app.cobiro.com/core/utils';
import { GetsAgencyAccessCommandPort } from '../../ports/primary/gets-agency-access-link.command-port';
import {
  GETS_ONE_CLIENT_DTO,
  GetsOneClientDtoPort,
} from '../../ports/secondary/gets-one-client.dto-port';
import {
  WORKSPACE_ID_GETTER,
  WorkspaceIdGetter,
  TEAM_ID_GETTER,
  TeamIdGetter,
} from '@app.cobiro.com/cobiro-pro/context';
import { ClientDTO } from '../../ports/secondary/client.dto';
import { GoesToPreviousStepCommandPort } from '../../ports/primary/goes-to-previous-step.command-port';
import { GoesToNextStepCommandPort } from '../../ports/primary/goes-to-next-step.command-port';
import {
  GETS_INTEGRATION_ERROR_DTO,
  GetsIntegrationErrorDtoPort,
} from '../../ports/secondary/gets-integration-error.dto-port';
import { IntegrationErrorDto } from '../../ports/secondary/integration-error.dto';
import { GetsNonCssIntegrationQueryPort } from '../../ports/primary/gets-non-css-integration.query-port';
import { IntegrationInfoDto } from '../../ports/secondary/integration-info.dto';
import { IsDummyIntegrationCreatedQueryPort } from '../../ports/primary/is-dummy-integration-created.query-port';
import { ChangesNoCacheFlagCommandPort } from '../../ports/primary/change-no-cache-flag.command-port';

@Injectable()
export class LabelManagerNonCssIntegrationState
  implements
    InitsNonCssIntegrationCommandPort,
    CreatesNonCssIntegrationQueryPort,
    GetsAgencyAccessCommandPort,
    GoesToNextStepCommandPort,
    GoesToPreviousStepCommandPort,
    GetsNonCssIntegrationQueryPort,
    IsDummyIntegrationCreatedQueryPort,
    ChangesNoCacheFlagCommandPort
{
  private _stepper: LabelManagerNonCssIntegrationStepperComponent | null = null;
  private _currentClient$: BehaviorSubject<string | null> = new BehaviorSubject(null);
  private readonly _currentStepIndex$ = new BehaviorSubject<number>(0);
  private readonly _isNoCache$ = new BehaviorSubject<boolean>(true);

  constructor(
    @Inject(GETS_ONE_CLIENT_DTO)
    private readonly _getsOneClientDtoPort: GetsOneClientDtoPort,
    @Inject(GETS_INTEGRATION_DTO)
    private readonly _getsIntegrationDtoPort: GetsIntegrationDtoPort,
    @Inject(CREATES_NON_CSS_INTEGRATION_DTO)
    private readonly _createsNonCssIntegrationDtoPort: CreatesNonCssIntegrationDtoPort,
    @Inject(GETS_INTEGRATION_ERROR_DTO)
    private readonly _getsIntegrationErrorDtoPort: GetsIntegrationErrorDtoPort,
    @Inject(APPLICATION_BUS)
    private readonly _applicationBus: ApplicationBus,
    private readonly _alert: HuiAlert,
    @Inject(STORAGE)
    private readonly _storage: Storage,
    @Inject(ENV_CONFIG)
    private readonly _getsConfig: GetsConfig,
    @Inject(WORKSPACE_ID_GETTER)
    private readonly _workspaceIdGetter: WorkspaceIdGetter,
    @Inject(TEAM_ID_GETTER)
    private readonly _teamIdGetter: TeamIdGetter,
  ) {}

  changeNoCacheFlag(value: boolean): void {
    this._isNoCache$.next(value);
  }

  isCreated(): Observable<{ isCreated: boolean; errors: string[] }> {
    const siteId = this._getSiteId();

    return combineLatest([
      this._getsIntegrationDtoPort.getIntegration(siteId),
      this._getsIntegrationErrorDtoPort.getIntegrationError(this._getSiteId()),
    ]).pipe(
      map(([integration, error]: [IntegrationInfoDto, IntegrationErrorDto]) => {
        const validationId: string[] = Array.of(
          ...(integration.adsId === '2000000002' ? ['Google Ads account'] : []),
          ...(integration.merchantId === '200000002' ? ['Google Merchant Center'] : []),
        );
        return {
          isCreated:
            integration.status === 'pending' && error.code === 'INTEGRATION_IN_TEMPORARY_STATE',
          errors: validationId,
        };
      }),
    );
  }

  getIframeLink(): Observable<string> {
    const siteId = this._getSiteId();
    const noCache = this._isNoCache$.getValue();
    const enviroment =
      this._getsConfig.get('name') === 'local' ? 'staging' : this._getsConfig.get('name');
    const params = encodeURIComponent(
      JSON.stringify({ internalID: siteId, environment: enviroment }),
    );
    const link = `${this._getsConfig.get('accessAgency')}&metadata=${params}${
      noCache ? '&nocache=true' : ''
    }`;

    return of(link);
  }

  getNonCssIntegration(): Observable<boolean> {
    const siteId = this._getSiteId();

    return combineLatest([
      this._getsIntegrationDtoPort.getIntegration(siteId),
      this._getsIntegrationErrorDtoPort.getIntegrationError(this._getSiteId()),
    ]).pipe(
      repeat({ delay: 10000 }),
      skipWhile(([integration, error]: [IntegrationInfoDto, IntegrationErrorDto]) => {
        return integration.status === 'pending' && error.code === 'INTEGRATION_IN_TEMPORARY_STATE';
      }),
      take(1),
      map(([integration, error]: [IntegrationInfoDto, IntegrationErrorDto]) => {
        this._applicationBus.dispatch(
          new IntegrationIdChangedEvent({
            id: integration.id,
            type: integration.type,
            status: integration.status,
            errors: error,
          }),
        );
        return true;
      }),
      catchError(() => {
        this._alert.open('error', '_something_went_wrong');
        return of(false);
      }),
    );
  }

  createIntegration(query: CreatesNonCssIntegrationQuery): Observable<boolean> {
    const siteId = this._getSiteId();
    const teamId = this._teamIdGetter.getTeamId();
    const workspaceId = this._workspaceIdGetter.getWorkspaceId();
    const clientId = this._currentClient$.getValue();
    return this._getsOneClientDtoPort
      .getOne({ clientId: clientId, workspaceId: workspaceId, teamId: teamId })
      .pipe(
        switchMap((client: ClientDTO) =>
          this._createsNonCssIntegrationDtoPort
            .createIntegration({
              siteId: siteId,
              websiteName: client.companyName,
              adsId: query.adsId,
              managerEmail: query.managerEmail,
              merchantId: query.merchantId,
            })
            .pipe(tap(() => this.goToNextStep())),
        ),
        catchError(() => {
          this._alert.open('error', '_something_went_wrong');
          return of(false);
        }),
      );
  }

  initNonCssIntegration({ stepper, clientId }: InitsNonCssIntegrationCommand): Observable<void> {
    this._stepper = stepper;
    this._currentClient$.next(clientId);
    this._currentStepIndex$.next(0);
    return of(void 0);
  }

  goToPreviousStep(): void {
    this._stepper.previous();
    this._currentStepIndex$.next(this._stepper.selectedIndex);
  }

  goToNextStep(): void {
    this._stepper.next();
    this._currentStepIndex$.next(this._stepper.selectedIndex);
  }

  private _getSiteId(): string {
    return String(this._storage.getItem('cobiro-pro-current-client'));
  }
}
