/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { Inject, Injectable } from '@angular/core';
import {
  GETS_COBIRO_PRO_AVAILABLE_TODOS_DTO,
  GetsAvailableTodosDtoPort,
} from '../ports/secondary/gets-available-todos.dto-port';
import { BehaviorSubject, combineLatest, Observable, of, zip } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { TodoQuery } from '../ports/primary/todo.query';
import { GetsTodosQuery } from '../ports/primary/gets-todos.query';
import {
  GETS_USER_DETAILS_DTO,
  GetsUserDetailsDtoPort,
} from '../ports/secondary/gets-user-details.dto-port';
import { UserDetailsDTO } from '../ports/secondary/user-details.dto';
import { CurrentClientsQuantityQueryPort } from '../ports/primary/current-clients-quantity.query-port';
import { CurrentMembersQuantityQueryPort } from '../ports/primary/current-members-quantity.query-port';
import { CurrentUpgradesQuantityQueryPort } from '../ports/primary/current-upgrades-quantity.query-port';
import { ShowOverviewSectionQueryPort } from '../ports/primary/show-overview-section.query-port';
import { GetsUserDetailsQueryPort } from '../ports/primary/gets-user-details.query-port';
import { ApplicationBus, APPLICATION_BUS } from '@cobiro/eda';
import { ClientsListChangedEvent } from '@app.cobiro.com/cobiro-pro-rewrite/clients';
import { UsersListChangedEvent } from '@app.cobiro.com/cobiro-pro-rewrite/users';
import { GetsLoadingStateCommandPort } from '../ports/primary/gets-loading-state.command-port';
import { GetsUserFromToken, GETS_USER_FROM_TOKEN } from '@app.cobiro.com/user/core';
import {
  GetsProductListDtoPort,
  GETS_PRODUCT_LIST_DTO,
} from '../ports/secondary/gets-product-list.dto-port';
import { GetsProductListQuery } from '../ports/primary/gets-product-list.query';
import { ProductQuery } from '../ports/primary/product.query';
import { ShowProductInfoCommandPort } from '../ports/primary/show-product-info.command-port';
import { MatDialog } from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { ProductInfoComponent } from '../../adapters/primary/ui/setup-modal/product-info/product-info.component';
import { GetsProductMarketingListQuery } from '../ports/primary/gets-product-marketing-list.query';
import {
  GetsProductMarketingListDtoPort,
  GETS_PRODUCT_MARKETING_LIST_DTO,
} from '../ports/secondary/gets-product-marketing-list.dto-port';
import { ProductMarketingQuery } from '../ports/primary/product-marketing.query';
import { OpenSetupFlowCommandPort } from '../ports/primary/open-setup-flow.command-port';
import {
  OpenSetupFlowClickedDispatcherPort,
  OPEN_SETUP_FLOW_CLICKED_DISPATCHER,
} from '../ports/secondary/dispatchers/open-setup-flow-clicked.dispatcher-port';
import { GetsShopListQuery } from '../ports/primary/gets-shop-list.query';
import { ClientListItemQuery } from '../ports/primary/client-list-item.query';
import {
  CobiroProClearStatesEvent,
  WorkspaceQuantityChangedEvent,
} from '@app.cobiro.com/core/events';
import { GetsWorkspaceQuantityQueryPort } from '../ports/primary/get-workspace-quantity.query-port';

@Injectable()
export class HomeState
  implements
    GetsTodosQuery,
    CurrentClientsQuantityQueryPort,
    CurrentMembersQuantityQueryPort,
    CurrentUpgradesQuantityQueryPort,
    ShowOverviewSectionQueryPort,
    GetsUserDetailsQueryPort,
    GetsLoadingStateCommandPort,
    GetsProductListQuery,
    ShowProductInfoCommandPort,
    GetsProductMarketingListQuery,
    OpenSetupFlowCommandPort,
    GetsShopListQuery,
    GetsWorkspaceQuantityQueryPort
{
  private lmShops$: BehaviorSubject<ClientListItemQuery[]> = new BehaviorSubject<
    ClientListItemQuery[]
  >(null);
  private allClientsQuantity$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  private allMembersQuantity$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  private notUpgradedClientsQuantity$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  private loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private workspaceQuantity$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  constructor(
    @Inject(GETS_COBIRO_PRO_AVAILABLE_TODOS_DTO)
    private readonly _getsAvailableTodosDto: GetsAvailableTodosDtoPort,
    @Inject(GETS_USER_DETAILS_DTO)
    private readonly _getsUserDetails: GetsUserDetailsDtoPort,
    @Inject(APPLICATION_BUS) private readonly _applicationBus: ApplicationBus,
    @Inject(GETS_USER_FROM_TOKEN) private readonly _getsUser: GetsUserFromToken,
    @Inject(GETS_PRODUCT_LIST_DTO) private readonly _getsProductListDtoPort: GetsProductListDtoPort,
    @Inject(GETS_PRODUCT_MARKETING_LIST_DTO)
    private readonly _getsProductMarketingListDtoPort: GetsProductMarketingListDtoPort,
    @Inject(OPEN_SETUP_FLOW_CLICKED_DISPATCHER)
    private readonly _openSetupFlowClickedDispatcherPort: OpenSetupFlowClickedDispatcherPort,
    private readonly _matDialog: MatDialog,
  ) {
    this._applicationBus
      .on(CobiroProClearStatesEvent)
      .pipe(
        tap(() => {
          this.allClientsQuantity$.next(null);
          this.allMembersQuantity$.next(null);
          this.notUpgradedClientsQuantity$.next(null);
          this.lmShops$.next(null);
        }),
      )
      .subscribe();

    this._applicationBus
      .on(WorkspaceQuantityChangedEvent)
      .pipe(
        tap((workspaceQuantityChanged: WorkspaceQuantityChangedEvent) =>
          this.workspaceQuantity$.next(workspaceQuantityChanged.numberOfWorkspaces),
        ),
      )
      .subscribe();

    zip(
      this._applicationBus.on(ClientsListChangedEvent),
      this._applicationBus.on(UsersListChangedEvent),
    )
      .pipe(
        tap(([clientsEvent, usersEvent]: [ClientsListChangedEvent, UsersListChangedEvent]) => {
          this.lmShops$.next(
            clientsEvent.updatedClients
              .map(client => ClientListItemQuery.fromClientDTO(client))
              .filter(
                client =>
                  !client.subscriptions.some(
                    subscription => subscription.productName === 'label-manager',
                  ),
              ),
          );
          const allClientsQuantity = clientsEvent.updatedClients.filter(
            client => !client.archived,
          ).length;
          const notUpgradedClientsQuantity = clientsEvent.updatedClients.filter(
            client => !client.archived && client.subscriptions,
          ).length;
          const allMembersQuantity = usersEvent.updatedUsers.length;

          this.allClientsQuantity$.next(allClientsQuantity);
          this.notUpgradedClientsQuantity$.next(notUpgradedClientsQuantity);
          this.allMembersQuantity$.next(allMembersQuantity);
          this.loading$.next(true);
        }),
      )
      .subscribe();
  }

  getWorkspaceQuantity(): Observable<number> {
    return this.workspaceQuantity$.asObservable();
  }

  getShops(): Observable<any[]> {
    return this.lmShops$.asObservable();
  }
  openSetupFlow(product: string): Observable<void> {
    return of(this._openSetupFlowClickedDispatcherPort.dispatch({ product: product }));
  }

  getAllProductsMarketing(product: string): Observable<ProductMarketingQuery> {
    return of(this._getsProductMarketingListDtoPort.getProductsMarketing(product));
  }

  showProductInfoModal(product: string): Observable<boolean> {
    return this._matDialog
      .open(ProductInfoComponent, {
        panelClass: 'cs-mat-dialog',
        scrollStrategy: new NoopScrollStrategy(),
        autoFocus: false,
        disableClose: false,
        data: {
          product: product,
        },
      })
      .afterClosed()
      .pipe(map(() => true));
  }

  getAllProducts(): Observable<ProductQuery[]> {
    return of(this._getsProductListDtoPort.getProducts());
  }

  getLoadingState(): Observable<boolean> {
    return this.loading$.asObservable();
  }

  getCurrentClientsQuantity(): Observable<number> {
    return this.allClientsQuantity$.asObservable();
  }

  getCurrentMembersQuantity(): Observable<number> {
    return this.allMembersQuantity$.asObservable();
  }

  getCurrentUpgradesQuantity(): Observable<number> {
    return this.notUpgradedClientsQuantity$.asObservable();
  }

  getUserDetails(): Observable<UserDetailsDTO> {
    const user = this._getsUser.get();
    return this._getsUserDetails.getUserDetails({ userId: user.userId });
  }

  showOverviewSection(): Observable<boolean> {
    return combineLatest([
      this.allMembersQuantity$.asObservable(),
      this.allClientsQuantity$.asObservable(),
    ]).pipe(
      map(
        ([memberQuantity, clientsQuantity]: [number, number]) =>
          memberQuantity > 1 || clientsQuantity > 0,
      ),
    );
  }

  getAllTodos(): Observable<TodoQuery[] | null> {
    return of(this._getsAvailableTodosDto.getAvailableTodos());
  }
}
