import { PRODUCT_LIST } from '../../../primary/clients/product-list.query';

export class SubscriptionDto {
  constructor(
    public readonly isTrial: boolean,
    public readonly trialExpiresAt: string | null,
    public readonly productName: string,
    public readonly planType: string,
    public readonly subscriptionId: string,
  ) {}
  static extractPlanType(planId: string): string {
    return planId.slice(planId.lastIndexOf('-') + 1);
  }
  static extractProductName(planId: string): string {
    const productName = planId.slice(0, planId.lastIndexOf('-'));
    return productName === 'business' ? PRODUCT_LIST.css : productName;
  }
}
