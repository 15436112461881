<div class="d-flex flex-column cs-mt-20" *ngIf="isTrial">
  <p class="cs-text-1" data-selector="cobiro-pro-confirm-payment-sub-header-trial">
    {{
      'cobiro_pro_setup_confirmation_trial_description'
        | language : (trialPeriod | date : 'dd.MM.yyyy')
    }}
  </p>
</div>
<div
  [ngClass]="isTrial ? 'cs-mt-0' : 'cs-mt-30'"
  class="cs-b-1 cs-b-shade-10 cs-pv-20 cs-ph-15 br-5"
>
  <ng-container *huiLoaderContainer="planEstimate$ | async as planEstimate">
    <div *ngIf="isTrial" class="d-flex justify-content-between cs-mb-10">
      <p class="cs-text-2" data-selector="payment-plans-subtotal-header">
        {{ '_due_today' | language }}
      </p>
      <p class="cs-text-2" data-selector="payment-plans-subtotal-sub-header">$0</p>
    </div>
    <div class="d-flex justify-content-between">
      <p class="cs-text-2" data-selector="payment-plans-subtotal-header">
        {{ '_subtotal' | language }}
      </p>
      <p class="cs-text-2" data-selector="payment-plans-subtotal-sub-header">
        ${{ planEstimate.nett }}
      </p>
    </div>
    <div class="d-flex justify-content-between cs-mt-10">
      <p class="cs-text-2" data-selector="payment-plans-vat">
        {{
          planEstimate.vatPercentage !== null
            ? ('payment_plans_vat_percentage' | language : planEstimate.vatPercentage)
            : ('payment_plans_vat' | language)
        }}
      </p>
      <p class="cs-text-2" data-selector="payment-plans-vat-value-or-select-country">
        {{
          planEstimate.vatValue !== null
            ? '$' + planEstimate.vatValue
            : ('payment_plans_select_country' | language)
        }}
      </p>
    </div>
    <ng-container *ngIf="discount$ | async as discount">
      <div class="d-flex justify-content-between align-items-center cs-mt-20">
        <div>
          <p class="cs-text-2" data-selector="payment-plans-discount-code">
            {{ 'payment_plans_discount_code' | language }}: {{ discount.code }}
          </p>
          <small class="cs-text-3">
            ({{ 'payment_plans_discount_code_applies_for' | language }}:
            {{ discount.duration.type }}
            <span *ngIf="discount.duration.type === 'period'">
              {{ discount.duration.value }}
              {{
                'payment_plans_discount_duration_period_' + discount.duration.unit.toLowerCase()
                  | language
              }}
            </span>
            <span *ngIf="discount.duration.type !== 'period'">
              {{
                'payment_plans_discount_duration_' + discount.duration.type.toLowerCase() | language
              }}
            </span>
            )
          </small>
        </div>
        <p class="cs-text-2">-{{ getDiscountValue(discount) }}</p>
      </div>
    </ng-container>
    <div class="d-flex justify-content-between cs-mt-5">
      <p class="cs-title-7">
        {{ 'payment_plans_total_incl_vat' | language }}
      </p>
      <div>
        <p class="cs-title-7">
          <span
            *ngIf="planEstimate.originalGrossPrice"
            class="cs-text-2 cs-c-shade-100 text-decoration-line-through cs-mr-10"
          >
            ${{ planEstimate.originalGrossPrice }}
          </span>
          ${{ planEstimate.gross }}
        </p>
      </div>
    </div>
  </ng-container>
</div>
