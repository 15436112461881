import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { CobiroProNoTeamSelectedEvent } from '@app.cobiro.com/core/events';
import { take } from 'rxjs';

@Injectable()
export class NoTeamSelectedEventHandler {
  eventsClasses = [CobiroProNoTeamSelectedEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(private readonly _router: Router, private readonly _activatedRoute: ActivatedRoute) {}

  handle([event]: [CobiroProNoTeamSelectedEvent]): void {
    this._router.navigate(['/', 'connect', 'no-team']);
  }
}
