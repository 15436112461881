import { NgModule } from '@angular/core';
import { CobiroProTopBarStateModule } from '../application/state/cobiro-pro-top-bar/cobiro-pro-top-bar.state-module';
import { HttpTeamsServiceModule } from '../adapters/secondary/infrastructure/http-team/http-teams.service-module';
import { provideApplicationEventsHandler } from '@cobiro/eda';
import { CobiroProClearStatesEventHandler } from '../adapters/primary/handlers/cobiro-pro-clear-state.event-handler';

@NgModule({
  imports: [CobiroProTopBarStateModule, HttpTeamsServiceModule],
  providers: [provideApplicationEventsHandler(CobiroProClearStatesEventHandler)],
})
export class CobiroProLayoutProvidersModule {}
