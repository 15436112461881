import { Injectable } from '@angular/core';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { GetsTrialAvailabilityListDtoPort } from '../../../../application/ports/secondary/gets-trial-availability.dto-port';
import { TrialAvailabilityDTO } from '../../../../application/ports/secondary/trial-availability.dto';
import { HasData } from '@cobiro/jsonapi';
import { catchError, map, Observable, of } from 'rxjs';

@Injectable()
export class HttpTrialAvailabilityService implements GetsTrialAvailabilityListDtoPort {
  constructor(private readonly _gateway: GatewayClient) {}

  getTrialAvailability(teamId: string): Observable<TrialAvailabilityDTO> {
    return this._gateway
      .get<HasData<{ trialsAvailable: Record<'css' | 'label-manager', boolean> }>>(
        `v1/sites/teams/${teamId}/trial-subscriptions-status`,
      )
      .pipe(
        map(response => ({
          css: response.data.attributes.trialsAvailable.css,
          labelManager: response.data.attributes.trialsAvailable['label-manager'],
        })),
        catchError(() => of({ css: false, labelManager: false })),
      );
  }
}
