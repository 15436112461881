/* eslint-disable max-lines-per-function */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { LinksGoogleAdsLinkingDto } from '../../../../application/ports/secondary/links-google-ads-account.dto';
import { LinksGoogleAdsAccountDtoPort } from '../../../../application/ports/secondary/links-google-ads-account.dto-port';
import { GetsGoogleAdsLinkingStatusDto } from '../../../../application/ports/secondary/gets-google-ads-linking-status.dto';
import { GetsGoogleAdsLinkingStatusDtoPort } from '../../../../application/ports/secondary/gets-google-ads-linking-status.dto-port';
import { HasData } from '@cobiro/jsonapi';
import { CreatesNonCssIntegrationDtoPort } from '../../../../application/ports/secondary/creates-non-css-integration.dto-port';
import { CreatesNonCssIntegrationDto } from '../../../../application/ports/secondary/creates-non-css-integration.dto';
import { GetsIntegrationDtoPort } from '../../../../application/ports/secondary/gets-integration.dto-port';
import { IntegrationErrorDto } from '../../../../application/ports/secondary/integration-error.dto';
import { GetsIntegrationErrorDtoPort } from '../../../../application/ports/secondary/gets-integration-error.dto-port';
import { IntegrationInfoDto } from '../../../../application/ports/secondary/integration-info.dto';

export interface GoogleAdsLinkingStatusModelAttributes {
  isLinked: boolean;
}

export interface GoogleAdsLinkingStatusModel {
  id: string;
  type: string;
  attributes: GoogleAdsLinkingStatusModelAttributes;
}

@Injectable()
export class HttpComlynIntegrationService
  implements
    GetsGoogleAdsLinkingStatusDtoPort,
    LinksGoogleAdsAccountDtoPort,
    CreatesNonCssIntegrationDtoPort,
    GetsIntegrationDtoPort,
    GetsIntegrationErrorDtoPort
{
  constructor(private readonly _client: GatewayClient) {}

  getIntegrationError(siteId: string): Observable<IntegrationErrorDto> {
    return this._client
      .get<HasData<{ code: string }>>(`v1/google-css/comlyn-integration/${siteId}/error`)
      .pipe(map(response => ({ code: response?.data?.attributes?.code ?? null })));
  }

  getIntegration(siteId: string): Observable<IntegrationInfoDto> {
    return this._client
      .get<
        HasData<{
          integrationId: string;
          status: 'pending' | 'success' | 'missing';
          integrationType: 'owned' | 'switched' | 'non-css';
          adsId: string;
          merchantId: string;
        }>
      >(`v1/google-css/comlyn-integration/${siteId}`)
      .pipe(
        map(response => ({
          id: response.data.attributes.integrationId,
          type: response.data.attributes.integrationType,
          status: response.data.attributes.status,
          adsId: response.data.attributes.adsId,
          merchantId: response.data.attributes.merchantId,
          errors: null,
        })),
      );
  }

  createIntegration(dto: CreatesNonCssIntegrationDto): Observable<boolean> {
    const body: HasData<CreatesNonCssIntegrationDto> = {
      data: {
        type: 'non-css-integration',
        attributes: {
          siteId: dto.siteId,
          adsId: dto.adsId,
          websiteName: dto.websiteName,
          managerEmail: dto.managerEmail,
          merchantId: dto.merchantId,
        },
      },
    };
    return this._client.post(`v1/google-css/non-css-integration`, body).pipe(map(() => true));
  }

  getStatus(dto: GetsGoogleAdsLinkingStatusDto): Observable<boolean> {
    return this._client
      .get(`v1/google-css/comlyn-integration/${dto.siteId}/ads-account-link-status`)
      .pipe(map(({ data }: { data: GoogleAdsLinkingStatusModel }) => data.attributes.isLinked));
  }

  linkGoogleAdsAccount(dto: LinksGoogleAdsLinkingDto): Observable<boolean> {
    return this._client
      .post(`v1/google-css/comlyn-integration/${dto.siteId}/link-ads-account`, {})
      .pipe(map(() => true));
  }
}
