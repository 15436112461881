/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */
import { Inject, Injectable } from '@angular/core';
import { OpenSetupFlowClickedEvent } from '@app.cobiro.com/cobiro-pro-rewrite/home';
import { ApplicationBus, APPLICATION_BUS } from '@cobiro/eda';
import { BehaviorSubject, combineLatest, filter, map, Observable, of, tap } from 'rxjs';
import { CobiroProSetupClientStepperComponent } from '../../../adapters/primary/ui/setup-client/setup-client-stepper/setup-client-stepper.component';
import { AddBillingAddressComponent } from '../../../adapters/primary/ui/setup-client/steps/add-billing-address/add-billing-address.component';
import { AddPaymentCardComponent } from '../../../adapters/primary/ui/setup-client/steps/add-payment-card/add-payment-card.component';
import { ChoosePlanComponent } from '../../../adapters/primary/ui/setup-client/steps/choose-plan/choose-plan.component';
import { ConfirmPaymentComponent } from '../../../adapters/primary/ui/setup-client/steps/confirm-payment/confirm-payment.component';
import { CreateShopComponent } from '../../../adapters/primary/ui/setup-client/steps/create-shop/create-shop.component';
import { CardQuery } from '../../ports/primary/payments/card.query';
import {
  GetsPaymentSourcesQueryPort,
  GETS_PAYMENT_SOURCES_QUERY_PORT,
} from '../../ports/primary/payments/gets-payment-sources.query-port';
import {
  GetsTeamSettingsQueryPort,
  GETS_TEAM_SETTINGS_QUERY_PORT,
} from '../../ports/primary/settings/gets-team-settings.query-port';
import { TeamSettingsQuery } from '../../ports/primary/settings/team-settings.query';
import { CompletesFirstStepCommand } from '../../ports/primary/setup/completes-first-step.command';
import { CompletesFirstStepCommandPort } from '../../ports/primary/setup/completes-first-step.command-port';
import { GetsCurrentClientQueryPort } from '../../ports/primary/setup/gets-current-client.query-port';
import { GetsCurrentIndexQueryPort } from '../../ports/primary/setup/gets-current-index.query-port';
import { GetsCurrentProductQueryPort } from '../../ports/primary/setup/gets-current-product.query-port';
import { GetsProductMarketingListQuery } from '../../ports/primary/setup/gets-product-marketing-list.query';
import { GetsStepsConfigurationQueryPort } from '../../ports/primary/setup/gets-steps-configuration.query-port';
import { GoesToNextStepCommandPort } from '../../ports/primary/setup/goes-to-next-step.command-port';
import { GoesToPreviousStepCommandPort } from '../../ports/primary/setup/goes-to-previous-step.command-port';
import { InitsSetupFlowCommand } from '../../ports/primary/setup/inits-setup-flow.command';
import { InitsSetupFlowCommandPort } from '../../ports/primary/setup/inits-setup-flow.command-port';
import { ProductMarketingQuery } from '../../ports/primary/setup/product-marketing.query';
import { SetsCurrentClientQueryPort } from '../../ports/primary/setup/sets-current-client.query-port';
import { SetsCurrentProductQueryPort } from '../../ports/primary/setup/sets-current-product.query-port';
import { StepConfigurationQuery } from '../../ports/primary/setup/step-configuration.query';
import { ClientDTO } from '../../ports/secondary/dto/clients/client.dto';
import {
  GetsProductMarketingListDtoPort,
  GETS_PRODUCT_MARKETING_LIST_DTO,
} from '../../ports/secondary/dto/setup/gets-product-marketing-list.dto-port';
import { IsTrialPeriodQueryPort } from '../../ports/primary/setup/is-trial-period.query-port';
import { GetsTrialPeriodQueryPort } from '../../ports/primary/setup/gets-trial-period.query-port';
import {
  GETS_TRIAL_AVAILABILITY_DTO,
  GetsTrialAvailabilityListDtoPort,
} from '../../ports/secondary/dto/setup/gets-trial-availability.dto-port';
import { TEAM_ID_GETTER, TeamIdGetter } from '@app.cobiro.com/cobiro-pro/context';
import { TrialAvailabilityDTO } from '../../ports/secondary/dto/setup/trial-availability.dto';
import { camelCase } from 'lodash';

@Injectable()
export class SetupFlowState
  implements
    GetsCurrentIndexQueryPort,
    InitsSetupFlowCommandPort,
    CompletesFirstStepCommandPort,
    GetsCurrentProductQueryPort,
    GoesToNextStepCommandPort,
    GoesToPreviousStepCommandPort,
    GetsStepsConfigurationQueryPort,
    GetsCurrentClientQueryPort,
    SetsCurrentProductQueryPort,
    SetsCurrentClientQueryPort,
    GetsProductMarketingListQuery,
    IsTrialPeriodQueryPort,
    GetsTrialPeriodQueryPort
{
  private _stepper: CobiroProSetupClientStepperComponent | null = null;
  private readonly _currentProduct$ = new BehaviorSubject<string | null>(null);
  private readonly _currentClient$ = new BehaviorSubject<ClientDTO | null>(null);
  private readonly _currentStepIndex$ = new BehaviorSubject<number>(0);
  private readonly _isTrial$ = new BehaviorSubject<TrialAvailabilityDTO>(null);

  constructor(
    @Inject(APPLICATION_BUS)
    private readonly _applicationBus: ApplicationBus,
    @Inject(GETS_TEAM_SETTINGS_QUERY_PORT)
    private readonly _getBillingAddress: GetsTeamSettingsQueryPort,
    @Inject(GETS_PAYMENT_SOURCES_QUERY_PORT)
    private readonly _getPaymentSources: GetsPaymentSourcesQueryPort,
    @Inject(GETS_PRODUCT_MARKETING_LIST_DTO)
    private readonly _getsProductMarketingDtoPort: GetsProductMarketingListDtoPort,
    @Inject(GETS_TRIAL_AVAILABILITY_DTO)
    private readonly _getsTrialAvailabilityListDtoPort: GetsTrialAvailabilityListDtoPort,
    @Inject(TEAM_ID_GETTER)
    private readonly _teamIdGetter: TeamIdGetter,
  ) {
    this._applicationBus
      .on(OpenSetupFlowClickedEvent)
      .pipe(
        tap((event: OpenSetupFlowClickedEvent) => {
          this._currentProduct$.next(event.product);
          this._currentClient$.next(null);
        }),
      )
      .subscribe();
  }
  getTrial(): Observable<void> {
    return this._getsTrialAvailabilityListDtoPort
      .getTrialAvailability(this._teamIdGetter.getTeamId())
      .pipe(
        tap(res => this._isTrial$.next(res)),
        map(() => void 0),
      );
  }
  isTrial(product: string): Observable<boolean> {
    return this._isTrial$.asObservable().pipe(
      filter(value => value !== null),
      map(res => res[camelCase(product)]),
    );
  }
  getAllProductsMarketing(product: string): Observable<ProductMarketingQuery> {
    return of(this._getsProductMarketingDtoPort.getProductsMarketing(product));
  }
  setCurrentClient(client: ClientDTO): void {
    this._currentClient$.next(client);
  }
  setCurrentProduct(product: string): void {
    this._currentProduct$.next(product);
  }

  getCurrentClient(): Observable<ClientDTO> {
    return this._currentClient$.asObservable();
  }

  getCurrentIndex(): Observable<number> {
    return this._currentStepIndex$.asObservable();
  }

  getStepsConfiguration(): Observable<
    StepConfigurationQuery<
      | CreateShopComponent
      | ChoosePlanComponent
      | AddBillingAddressComponent
      | AddPaymentCardComponent
      | ConfirmPaymentComponent
    >[]
  > {
    return combineLatest([
      this._currentClient$.asObservable(),
      this._getBillingAddress.getTeamSettings(),
      this._getPaymentSources.getPaymentSourcesQuery(),
    ]).pipe(
      map(([client, teamSettings, cards]: [ClientDTO, TeamSettingsQuery, CardQuery[]]) => {
        return [
          new StepConfigurationQuery(
            'cobiro_pro_shop_details',
            'create-shop',
            false,
            true,
            !client,
            CreateShopComponent,
          ),
          new StepConfigurationQuery(
            'cobiro_pro_clients_select_plan',
            'choose-plan',
            true,
            true,
            true,
            ChoosePlanComponent,
          ),
          new StepConfigurationQuery(
            'cobiro_pro_team_setup_billing',
            'add-billing-address',
            true,
            true,
            teamSettings.isProvided(),
            AddBillingAddressComponent,
          ),
          new StepConfigurationQuery(
            'cobiro_pro_clients_payment_method',
            'add-payment-method',
            false,
            true,
            cards.length === 0,
            AddPaymentCardComponent,
          ),
          new StepConfigurationQuery(
            'cobiro_pro_clients_confirmation',
            'confirm-payment',
            true,
            true,
            true,
            ConfirmPaymentComponent,
          ),
        ].filter(step => step.shown);
      }),
    );
  }

  getCurrentProduct(): Observable<string> {
    return this._currentProduct$.asObservable();
  }

  completeFirstStep(command: CompletesFirstStepCommand): Observable<void> {
    this.goToNextStep();
    this._currentClient$.next(command.client);
    return of(void 0);
  }

  initSetupFlow({ stepper }: InitsSetupFlowCommand): Observable<void> {
    this._stepper = stepper;
    this._currentStepIndex$.next(0);
    return of(void 0);
  }

  goToPreviousStep(): void {
    this._stepper.previous();
    this._currentStepIndex$.next(this._stepper.selectedIndex);
  }

  goToNextStep(): void {
    this._stepper.next();
    this._currentStepIndex$.next(this._stepper.selectedIndex);
  }
}
