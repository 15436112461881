import { TeamDTO } from '../secondary/team.dto';

export class TeamQuery {
  constructor(
    public readonly id: string,
    public readonly name: string,
    readonly avatar?: string,
    readonly role?: string,
  ) {}

  static fromTeamDTO(dto: TeamDTO): TeamQuery {
    return new TeamQuery(dto.id, dto.name, dto.avatar, dto.role);
  }
}
