import { NgModule } from '@angular/core';
import { HttpTeamsServiceModule } from '../adapters/secondary/infrastructure/http-teams/http-teams.service-module';
import { TeamsStateModule } from '../application/states/teams/teams.state-module';
import { provideApplicationEventsHandler } from '@cobiro/eda';
import { CobiroProTeamCreatedEventHandler } from '../adapters/primary/handlers/cobiro-pro-team-created.event-handler';
import { HttpGetsUserDetailsServiceModule } from '../adapters/secondary/infrastructure/http-user-details/http-gets-user-details.service-module';

@NgModule({
  imports: [HttpTeamsServiceModule, HttpGetsUserDetailsServiceModule, TeamsStateModule],
  providers: [provideApplicationEventsHandler(CobiroProTeamCreatedEventHandler)],
})
export class CobiroProRewriteTeamsProvidersModule {}
