import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { CreateWorkspaceCommand } from './create-workspace.command';

export const CREATE_WORKSPACE_COMMAND = new InjectionToken<CreateWorkspaceCommandPort>(
  'CREATE_WORKSPACE_COMMAND',
);

export interface CreateWorkspaceCommandPort {
  createWorkspace(command: CreateWorkspaceCommand): Observable<void>;
}
