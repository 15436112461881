import { InjectionToken } from '@angular/core';
import { SetsClientsListSortCommand } from './sets-clients-list-sort.command';

export interface SetsAllTeamClientsListSortCommandPort {
  setSort(command: SetsClientsListSortCommand): void;
}

export const SETS_ALL_TEAM_CLIENTS_LIST_SORT_COMMAND =
  new InjectionToken<SetsAllTeamClientsListSortCommandPort>(
    'SETS_ALL_TEAM_CLIENTS_LIST_SORT_COMMAND',
  );
