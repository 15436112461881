import { NgModule } from '@angular/core';
import { ClientsListChangedEventDispatcher } from '../../../adapters/secondary/dispatchers/clients-list-changed.event-dispatcher';
import { CLIENTS_LIST_CHANGED_DISPATCHER } from '../../ports/secondary/dispatchers/clients-list-changed.dispatcher-port';
import { AllTeamClientsListState } from './all-team-clients-list.state';
import { GETS_ALL_TEAM_CLIENTS_LIST_PAGINATION_QUERY } from '../../ports/primary/clients/gets-all-team-clients-list-pagination.query-port';
import { SETS_ALL_TEAM_CLIENTS_LIST_SORT_COMMAND } from '../../ports/primary/clients/sets-all-team-clients-list-sort.command-port';
import { GETS_ALL_TEAM_CLIENTS_QUERY } from '../../ports/primary/clients/gets-all-team-clients.query-port';
import { LOAD_ALL_TEAM_CLIENTS_COMMAND } from '../../ports/primary/clients/load-all-team-clients.command-port';
import { SETS_ALL_TEAM_CLIENTS_LIST_SEARCH_PHRASE_COMMAND } from '../../ports/primary/clients/sets-all-team-clients-search-phrase.command-port';
import { SETS_ALL_TEAM_CLIENTS_LIST_PAGE_COMMAND } from '../../ports/primary/clients/sets-all-team-clients-list-page.command-port';
import { RESETS_ALL_TEAM_CLIENTS_LIST_PAGINATION_COMMAND } from '../../ports/primary/clients/resets-all-team-clients-list-pagination.command-port';

@NgModule({
  providers: [
    AllTeamClientsListState,
    { provide: LOAD_ALL_TEAM_CLIENTS_COMMAND, useExisting: AllTeamClientsListState },
    { provide: GETS_ALL_TEAM_CLIENTS_QUERY, useExisting: AllTeamClientsListState },
    { provide: GETS_ALL_TEAM_CLIENTS_LIST_PAGINATION_QUERY, useExisting: AllTeamClientsListState },
    { provide: SETS_ALL_TEAM_CLIENTS_LIST_SORT_COMMAND, useExisting: AllTeamClientsListState },
    {
      provide: SETS_ALL_TEAM_CLIENTS_LIST_SEARCH_PHRASE_COMMAND,
      useExisting: AllTeamClientsListState,
    },
    { provide: SETS_ALL_TEAM_CLIENTS_LIST_PAGE_COMMAND, useExisting: AllTeamClientsListState },
    {
      provide: RESETS_ALL_TEAM_CLIENTS_LIST_PAGINATION_COMMAND,
      useExisting: AllTeamClientsListState,
    },
    { provide: CLIENTS_LIST_CHANGED_DISPATCHER, useClass: ClientsListChangedEventDispatcher },
  ],
})
export class AllTeamClientsListStateModule {}
