import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { ClientSubscriptionDto } from './clients-subscription.dto';
import { GetsAllClientsSubsciptionsByTeamDto } from './gets-all-clients-subscriptions-by-team.dto';

export const GETS_ALL_CLIENTS_SUBSCRIPTIONS_BY_TEAM_DTO_PORT =
  new InjectionToken<GetsAllClientsSubsciptionsByTeamDtoPort>(
    'GETS_ALL_CLIENTS_SUBSCRIPTIONS_BY_TEAM_DTO_PORT',
  );

export interface GetsAllClientsSubsciptionsByTeamDtoPort {
  getAllSubscriptionsByTeam(
    dto: GetsAllClientsSubsciptionsByTeamDto,
  ): Observable<ClientSubscriptionDto[]>;
}
