import { NgModule } from '@angular/core';
import { HttpTrialAvailabilityService } from './http-trial-availability.service';
import { GETS_TRIAL_AVAILABILITY_DTO } from '../../../../application/ports/secondary/dto/setup/gets-trial-availability.dto-port';

@NgModule({
  providers: [
    HttpTrialAvailabilityService,
    {
      provide: GETS_TRIAL_AVAILABILITY_DTO,
      useExisting: HttpTrialAvailabilityService,
    },
  ],
})
export class HttpTrialAvailabilityServiceModule {}
