import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const TOGGLE_SIDEBAR_COMMAND = new InjectionToken<ToggleSidebarCommandPort>(
  'TOGGLE_SIDEBAR_COMMAND',
);

export interface ToggleSidebarCommandPort {
  toggleSidebar(value: boolean): Observable<void>;
}
