import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, catchError, of, take } from 'rxjs';
import {
  LoadsWorkspacesQueryPort,
  LOADS_WORKSPACES_QUERY,
} from '../../../application/ports/primary/loads-workspaces.query-port';
import { WorkspaceDto } from '../../../application/ports/secondary/dto/workspace.dto';

@Injectable()
export class LoadWorkspacesResolver {
  constructor(
    @Inject(LOADS_WORKSPACES_QUERY) private readonly _loadWorkspaces: LoadsWorkspacesQueryPort,
    private readonly _router: Router,
  ) {}

  resolve(snapshot: ActivatedRouteSnapshot): Observable<WorkspaceDto[]> {
    return this._loadWorkspaces.loadWorkspaces(snapshot.paramMap.get('teamId')).pipe(
      take(1),
      catchError(() => {
        this._router.navigate(['/connect']);
        return of([] as WorkspaceDto[]);
      }),
    );
  }
}
