<ng-container
  *huiLoaderContainer="{
    upgradablePlans: upgradablePlans$ | async,
    selectedPaymentPlan: selectedPaymentPlan$ | async,
    bestValue: bestValue$ | async
  } as planConfig"
>
  <div class="d-flex flex-md-row flex-column-reverse justify-content-center align-items-start">
    <div
      *ngFor="let plan of planConfig.upgradablePlans"
      class="cs-max-width-360-xs d-flex flex-column flex-1 cs-bg-shade-0 cs-b-1 br-10 cs-ph-15 cs-pv-40 cs-mb-10 cs-mr-10 align-items-center text-center"
      [ngClass]="{
        'cs-asset-bg-stars-1 cs-pt-16': planConfig.bestValue === plan.id,
        'cs-b-primary-100': planConfig.selectedPaymentPlan === plan.id,
        'cs-b-shade-10': planConfig.selectedPaymentPlan !== plan.id
      }"
    >
      <div
        *ngIf="planConfig.bestValue === plan.id"
        class="position-relative best-value-top-40 cs-c-shade-0 cs-bg-primary-100 cs-ph-20 cs-pv-3 cs-text-2 br-b-10"
        data-selector="cobiro-pro-setup-best-value-selection"
      >
        {{ 'cobiro_pro_setup_best_value_plan_selection' | language }}
      </div>
      <img
        class="cs-width-60-xs cs-height-60-xs cs-pb-20"
        [src]="'/assets/apps/icon/setup_' + productName + '.svg'"
      />
      <h1
        class="cs-title-5 cs-mb-10"
        [attr.data-selector]="'cobiro-pro-setup-' + plan.id + '-header'"
      >
        {{ 'cobiro_pro_setup_' + plan.id | language }}
      </h1>

      <div class="cs-card cs-bg-shade-6 cs-b-none w-100 cs-pv-20">
        <span
          *ngIf="plan.discount"
          class="cs-ph-15 cs-badge-primary br-15"
          [attr.data-selector]="'cobiro-pro-setup-' + plan.id + '-discount'"
        >
          {{ 'payment_plans_save' | language }}
          {{ plan.discount }}
        </span>
        <p
          class="cs-title-2"
          [attr.data-selector]="'cobiro-pro-setup-' + plan.id + '-price-monthly'"
          [ngClass]="planConfig.bestValue === plan.id ? 'cs-mt-10' : 'cs-mt-30'"
        >
          {{ plan.price }}/mo
        </p>
        <p
          class="cs-text-3 cs-mt-10 cs-mb-20"
          [attr.data-selector]="'cobiro-pro-setup-' + plan.id + '-description'"
        >
          {{ plan.description | language }}
        </p>
        <button
          class="cs-btn w-100"
          [attr.data-selector]="'cobiro-pro-setup-' + plan.id + '-choose-button'"
          [ngClass]="{
            'cs-btn-primary': planConfig.selectedPaymentPlan === plan.id,
            'cs-btn-stroked cs-bg-shade-0': planConfig.selectedPaymentPlan !== plan.id
          }"
          (click)="goToProduct(plan.id)"
        >
          {{
            (isTrial
              ? 'cobiro_pro_setup_activate_trial_button'
              : 'cobiro_pro_setup_choose_plan_button'
            ) | language
          }}
        </button>
      </div>
      <div class="d-flex flex-column align-self-start cs-mt-30 cs-ml-20">
        <div
          *ngFor="let item of plan.advantages"
          class="cs-z-index-2 cs-mb-10 d-flex justify-content-left"
        >
          <div class="d-flex align-items-start cs-mr-10 flex-wrap">
            <mat-icon
              svgIcon="checkmark-green-circled-2"
              class="cs-size-sm cs-c-shade-0 cs-mr-5 cs-mt-3"
            ></mat-icon>
            <p class="cs-text-1 text-left cs-max-width-217-xs">
              {{ item | language }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
