import { InjectionToken } from '@angular/core';

export interface SetsAllTeamClientsListPageCommandPort {
  setClientsListPage(pageIndex: number): void;
}

export const SETS_ALL_TEAM_CLIENTS_LIST_PAGE_COMMAND =
  new InjectionToken<SetsAllTeamClientsListPageCommandPort>(
    'SETS_ALL_TEAM_CLIENTS_LIST_PAGE_COMMAND',
  );
