/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import {
  SELECTS_WORKSPACES_CONTEXT_STORAGE,
  SelectsWorkspacesContextStoragePort,
} from '../../../application/ports/secondary/context/selects-workspaces-context.storage-port';
import { WorkspacesContext } from '../../../application/ports/secondary/context/workspaces-context';
import { TEAM_ID_GETTER, TeamIdGetter } from '@app.cobiro.com/cobiro-pro/context';
import { Router, UrlTree } from '@angular/router';
import {
  GETS_MEMBER_WORKSPACES_DTO,
  GetsMemberWorkspacesDtoPort,
} from '../../../application/ports/secondary/dto/gets-member-workspaces.dto-port';
import { WorkspaceDto } from '../../../application/ports/secondary/dto/workspace.dto';

@Injectable()
export class HasWorkspacesGuard {
  constructor(
    @Inject(GETS_MEMBER_WORKSPACES_DTO)
    private readonly _getsMemberWorkspacesDtoPort: GetsMemberWorkspacesDtoPort,
    @Inject(TEAM_ID_GETTER) private readonly _teamIdGetter: TeamIdGetter,
    private readonly _router: Router,
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    const teamId = this._teamIdGetter.getTeamId();
    return this._getsMemberWorkspacesDtoPort.getsMembersWorkspace(teamId).pipe(
      take(1),
      map((dto: WorkspaceDto[]) => {
        if (dto.length === 0) {
          return this._router.createUrlTree([`/connect/${teamId}`]);
        }
        return true;
      }),
      tap({
        error: () => {
          return this._router.createUrlTree([`/connect`]);
        },
      }),
      catchError(() => {
        this._router.navigate(['/connect']);
        return of(false);
      }),
    );
  }
}
