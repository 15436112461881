<div
  class="position-relative d-flex flex-column align-items-center cs-bg-shade-0 h-100 cs-ph-40 cs-pb-40"
>
  <mat-icon
    svgIcon="close"
    class="position-relative cs-top-10 cs-left-25 cs-cursor-pointer cs-z-index-1 align-self-end"
    data-selector="cobiro-pro-create-workspace-close-modal"
    matDialogClose
  ></mat-icon>

  <div class="cs-emoji-circle cs-emoji-circle-primary cs-mt-5">🏢</div>

  <h4 class="cs-title-4 cs-mt-20">{{ 'cobiro_pro_workspace_create_new_title' | language }}</h4>
  <p class="cs-text-1 text-center cs-mt-10">
    {{ 'cobiro_pro_workspace_create_new_description' | language }}
  </p>
  <form class="d-flex flex-column justify-content-between w-100 cs-mt-20 h-100" [formGroup]="form">
    <div class="cs-control-wrapper cs-mb-20">
      <label class="cs-label" for="company-name-input">
        {{ '_company_name' | language }}
        <span class="cs-c-error">{{ '_required' | language }}</span>
      </label>
      <input
        class="cs-input"
        data-selector="profile-company-name-input"
        formControlName="companyName"
        id="company-name-input"
      />
      <p
        *ngIf="hasError('companyName')"
        class="cs-control-error"
        data-selector="profile-company-name-required-message"
      >
        {{ '_this_field_is_required' | language }}
      </p>
    </div>

    <div *ngIf="companyTypes$ | async as companyTypes" class="cs-control-wrapper cs-mb-20">
      <label class="cs-label" for="company-type">
        {{ 'create_profile_company_details_company_type_placeholder' | language }}
        <span class="cs-c-error">{{ '_required' | language }}</span>
      </label>
      <mat-form-field class="cs-dropdown">
        <mat-select
          disableRipple="true"
          formControlName="companyType"
          id="company-type"
          panelClass="cs-dropdown-options"
        >
          <mat-option *ngFor="let companyType of companyTypes" [value]="companyType.value">
            {{ companyType.displayValue | language }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <p
        *ngIf="hasError('companyType')"
        class="cs-control-error"
        data-selector="profile-company-type-required-message"
      >
        {{ '_this_field_is_required' | language }}
      </p>
    </div>

    <div
      *ngIf="showNumberOfEmployees && numberOfEmployees$ | async as numberOfEmployeesList"
      class="cs-control-wrapper cs-mb-20"
    >
      <label class="cs-label" for="number-of-employees">
        {{ 'create_profile_company_details_number_of_employees_placeholder' | language }}
      </label>
      <mat-form-field class="cs-dropdown">
        <mat-select
          disableRipple="true"
          formControlName="numberOfEmployees"
          id="number-of-employees"
          panelClass="cs-dropdown-options"
        >
          <mat-option [value]="null"> --- </mat-option>
          <mat-option
            *ngFor="let numberOfEmployees of numberOfEmployeesList"
            [value]="numberOfEmployees.value"
          >
            {{ numberOfEmployees.displayValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div
      *ngIf="numberOfClients$ | async as numberOfClientsList"
      class="cs-control-wrapper cs-mb-20"
    >
      <label class="cs-label" for="number-of-clients">
        {{ 'create_profile_company_details_number_of_clients_placeholder' | language }}
      </label>
      <mat-form-field class="cs-dropdown">
        <mat-select
          disableRipple="true"
          formControlName="numberOfClients"
          id="number-of-clients"
          panelClass="cs-dropdown-options"
        >
          <mat-option [value]="null"> --- </mat-option>
          <mat-option
            *ngFor="let numberOfClients of numberOfClientsList"
            [value]="numberOfClients.value"
          >
            {{ numberOfClients.displayValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <button
      class="cs-btn cs-btn-primary cs-mt-40"
      data-selector="cbr-pro-create-workspace-submit-btn"
      type="submit"
      [disabled]="form.invalid"
      [matDialogClose]="form.value"
    >
      {{ 'cobiro_pro_team_create_new_button' | language }}
    </button>
  </form>
</div>
