import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { SetCurrentWorkspaceCommand } from './set-current-workspace.command';

export const SET_CURRENT_WORKSPACE_COMMAND = new InjectionToken<SetCurrentWorkspaceCommandPort>(
  'SET_CURRENT_WORKSPACE_COMMAND',
);

export interface SetCurrentWorkspaceCommandPort {
  setCurrentWorkspace(command: SetCurrentWorkspaceCommand): Observable<void>;
}
