import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { Inject, Injectable } from '@angular/core';
import { take } from 'rxjs';
import {
  PatchesWorkspacesContextStoragePort,
  PATCHES_WORKSPACES_CONTEXT_STORAGE,
} from '../../../application/ports/secondary/context/patches-workspaces-context.storage-port';
import { WorkspacesChangedEvent } from '../../../application/events/workspaces-changed.event';

@Injectable()
export class WorkspacesChangedEventHandler implements ApplicationEventsHandler {
  eventsClasses = [WorkspacesChangedEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(
    @Inject(PATCHES_WORKSPACES_CONTEXT_STORAGE)
    private readonly _patchesWorkspacesContextStoragePort: PatchesWorkspacesContextStoragePort,
  ) {}

  handle([event]: [WorkspacesChangedEvent]) {
    this._patchesWorkspacesContextStoragePort.patch({ list: event.workspaces }).pipe(take(1));
  }
}
