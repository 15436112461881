import { InjectionToken } from '@angular/core';
import { TrialAvailabilityDTO } from './trial-availability.dto';
import { Observable } from 'rxjs';

export const GETS_TRIAL_AVAILABILITY_DTO = new InjectionToken<GetsTrialAvailabilityListDtoPort>(
  'GETS_TRIAL_AVAILABILITY_DTO',
);

export interface GetsTrialAvailabilityListDtoPort {
  getTrialAvailability(teamId: string): Observable<TrialAvailabilityDTO>;
}
