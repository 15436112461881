import { InjectionToken } from '@angular/core';

export interface ResetsAllTeamClientsListPaginationCommandPort {
  resetClientListPagination(): void;
}

export const RESETS_ALL_TEAM_CLIENTS_LIST_PAGINATION_COMMAND =
  new InjectionToken<ResetsAllTeamClientsListPaginationCommandPort>(
    'RESETS_ALL_TEAM_CLIENTS_LIST_PAGINATION_COMMAND',
  );
