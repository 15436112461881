import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientDTO } from '../../secondary/dto/clients/client.dto';

export const LOAD_ALL_TEAM_CLIENTS_COMMAND = new InjectionToken<LoadAllTeamClientsCommandPort>(
  'LOAD_ALL_TEAM_CLIENTS_COMMAND',
);

export interface LoadAllTeamClientsCommandPort {
  loadAllClients(): Observable<ClientDTO[]>;
}
