import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { ClientListItemQuery } from './client-list-item.query';

export const GETS_ALL_TEAM_CLIENTS_QUERY = new InjectionToken<GetsAllTeamClientsQueryPort>(
  'GETS_ALL_TEAM_CLIENTS_QUERY',
);

export interface GetsAllTeamClientsQueryPort {
  getsPaginatedClients(): Observable<ClientListItemQuery[]>;
  getTotalClients(): Observable<number>;
}
