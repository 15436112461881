import { NgModule } from '@angular/core';
import { CREATES_TEAM_QUERY_PORT } from '../../ports/primary/creates-team.query-port';
import { GETS_COMPANY_TYPES_QUERY } from '../../ports/primary/gets-company-types.query-port';
import { GETS_NUMBER_OF_CLIENTS_QUERY } from '../../ports/primary/gets-number-of-clients.query-port';
import { GETS_NUMBER_OF_EMPLOYEES_QUERY } from '../../ports/primary/gets-number-of-employees.query-port';
import { TeamsState } from './teams.state';

@NgModule({
  providers: [
    TeamsState,
    { provide: GETS_COMPANY_TYPES_QUERY, useExisting: TeamsState },
    { provide: GETS_NUMBER_OF_CLIENTS_QUERY, useExisting: TeamsState },
    { provide: GETS_NUMBER_OF_EMPLOYEES_QUERY, useExisting: TeamsState },
    { provide: CREATES_TEAM_QUERY_PORT, useExisting: TeamsState },
  ],
})
export class TeamsStateModule {}
