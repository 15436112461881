/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { CreatesNewSubscriptionDtoPort } from '../../../../application/ports/secondary/dto/payments/creates-new-subscription.dto-port';
import { CreateNewSubscriptionDto } from '../../../../application/ports/secondary/dto/payments/create-new-subscription.dto';
import { PAYMENT_STATUS } from '../../../../application/ports/secondary/dto/payments/payment-status.enum';

export enum ERROR_CODES {
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
}

@Injectable()
export class HttpManagesPlanService implements CreatesNewSubscriptionDtoPort {
  private readonly ERRORS: Map<ERROR_CODES, PAYMENT_STATUS> = new Map([
    [ERROR_CODES.INSUFFICIENT_FUNDS, PAYMENT_STATUS.INSUFFICIENT_FUNDS],
  ]);

  constructor(private readonly _gateway: GatewayClient) {}

  createNewSubscription(dto: CreateNewSubscriptionDto): Observable<PAYMENT_STATUS> {
    const { clientId, companyName, planId, cardId, discountCode, workspaceId } = dto;

    return this._gateway
      .post(`v1/payments/workspaces/${workspaceId}/change-site-plan`, {
        data: {
          type: 'change-site-plan',
          attributes: {
            siteId: parseInt(clientId),
            companyName: companyName,
            planId,
            paymentSourceId: cardId,
            discountCode: discountCode ?? null,
            isTrial: dto.isTrial,
          },
        },
      })
      .pipe(map(() => PAYMENT_STATUS.SUCCESS));
  }
}
