import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const GETS_TRIAL_PERIOD_QUERY = new InjectionToken<GetsTrialPeriodQueryPort>(
  'GETS_TRIAL_PERIOD_QUERY',
);

export interface GetsTrialPeriodQueryPort {
  getTrial(): Observable<void>;
}
