<div
  class="position-relative cs-max-width-800-xs d-flex flex-column mx-auto cs-ph-40 cs-pb-40 justify-content-between align-items-center cs-bg-shade-0"
>
  <div class="d-flex flex-column cs-mb-30">
    <h3 class="cs-title-4 cs-mb-15 text-center" data-selector="cobiro-pro-setup-select-plan-header">
      {{ 'cobiro_pro_setup_select_a_plan' | language }}
    </h3>
    <div
      *ngIf="isTrial"
      class="text-center cs-badge cs-badge-success cs-mh-40 br-15 cs-text-1 cs-c-success d-flex justify-content-center align-items-center"
    >
      <mat-icon svgIcon="checkmark-green-circled-2" class="cs-size-sm cs-c-shade-0 cs-mr-5">
      </mat-icon>
      {{ 'cobiro_pro_setup_trial_description' | language }}
    </div>
  </div>
  <lib-cobiro-pro-subscription-list
    class="w-100"
    [isTrial]="isTrial"
    [product]="productName$ | async"
    (savedPlanClicked)="onSavedPlanClicked($event)"
  ></lib-cobiro-pro-subscription-list>
</div>
