import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { Inject, Injectable } from '@angular/core';
import { CobiroProTeamCreatingEvent } from '@app.cobiro.com/core/events';
import { take } from 'rxjs';
import {
  CREATES_TEAM_QUERY_PORT,
  CreatesTeamQueryPort,
} from '../../../application/ports/primary/creates-team.query-port';

@Injectable()
export class CobiroProTeamCreatedEventHandler implements ApplicationEventsHandler {
  eventsClasses = [CobiroProTeamCreatingEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(
    @Inject(CREATES_TEAM_QUERY_PORT)
    private readonly _createsTeamQueryPort: CreatesTeamQueryPort,
  ) {}

  handle([event]: [CobiroProTeamCreatingEvent]): void {
    this._createsTeamQueryPort.createTeam().pipe(take(1)).subscribe();
  }
}
