import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CobiroProTopBarDropdownComponent } from './cobiro-pro-top-bar-dropdown.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [CobiroProTopBarDropdownComponent],
  imports: [CommonModule, LanguageModule, MatIconModule, MatMenuModule, RouterModule],
  exports: [CobiroProTopBarDropdownComponent],
})
export class CobiroProTopBarDropdownComponentModule {}
