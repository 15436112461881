import { NgModule } from '@angular/core';
import { GETS_ALL_CLIENTS_SUBSCRIPTIONS_DTO_PORT } from '../../../../application/ports/secondary/dto/clients/gets-all-clients-subscriptions.dto-port';
import { GETS_CLIENT_SUBSCRIPTIONS_DTO_PORT } from '../../../../application/ports/secondary/dto/clients/gets-client-subscriptions.dto-port';
import { HttpClientsProductService } from './http-clients-products.service';
import { GETS_ALL_CLIENTS_SUBSCRIPTIONS_BY_TEAM_DTO_PORT } from '../../../../application/ports/secondary/dto/clients/gets-all-clients-subscriptions-by-team.dto-port';

@NgModule({
  providers: [
    HttpClientsProductService,
    {
      provide: GETS_ALL_CLIENTS_SUBSCRIPTIONS_BY_TEAM_DTO_PORT,
      useExisting: HttpClientsProductService,
    },
    { provide: GETS_CLIENT_SUBSCRIPTIONS_DTO_PORT, useExisting: HttpClientsProductService },
    { provide: GETS_ALL_CLIENTS_SUBSCRIPTIONS_DTO_PORT, useExisting: HttpClientsProductService },
  ],
})
export class HttpClientsProductServiceModule {}
