import { NgModule } from '@angular/core';
import { ADDS_CLIENT_DTO } from '../../../../application/ports/secondary/dto/clients/adds-client.dto-port';
import { DELETES_CLIENT_DTO } from '../../../../application/ports/secondary/dto/clients/deletes-client.dto-port';
import { GETS_ALL_CLIENT_DTO } from '../../../../application/ports/secondary/dto/clients/gets-all-client.dto-port';
import { GETS_ONE_CLIENT_DTO } from '../../../../application/ports/secondary/dto/clients/gets-one-client.dto-port';
import { SETS_CLIENT_DTO } from '../../../../application/ports/secondary/dto/clients/sets-client.dto-port';
import { HttpClientsService } from './http-clients.service';
import { GETS_ALL_TEAM_CLIENT_DTO } from '../../../../application/ports/secondary/dto/clients/gets-all-team-client.dto-port';

@NgModule({
  providers: [
    HttpClientsService,
    { provide: ADDS_CLIENT_DTO, useExisting: HttpClientsService },
    { provide: SETS_CLIENT_DTO, useExisting: HttpClientsService },
    { provide: GETS_ALL_CLIENT_DTO, useExisting: HttpClientsService },
    { provide: GETS_ALL_TEAM_CLIENT_DTO, useExisting: HttpClientsService },
    { provide: GETS_ONE_CLIENT_DTO, useExisting: HttpClientsService },
    { provide: DELETES_CLIENT_DTO, useExisting: HttpClientsService },
  ],
})
export class HttpClientsServiceModule {}
