import { NgModule } from '@angular/core';
import { provideApplicationEventsHandler } from '@cobiro/eda';
import { CurrentWorkspaceChangedEventHandler } from '../adapters/primary/handlers/current-workspace-changed.event-handler';
import { HttpWorkspacesServiceModule } from '../adapters/secondary/http-workspaces/http-workspaces.service-module';
import { InMemoryWorkspacesContextStorageModule } from '../adapters/secondary/in-memory-workspaces-context/in-memory-workspaces-context.storage-module';
import { WorkspacesStateModule } from '../application/state/workspaces.state-module';
import { HasWorkspacesGuard } from '../adapters/primary/guards/has-workspaces.guard';
import { HttpTeamsServiceModule } from '../adapters/secondary/http-teams/http-teams.service-module';
import { CobiroProWorkspaceSelectedEventHandler } from '../adapters/primary/handlers/cobiro-pro-workspace-selected.event-handler';
import { CobiroProWorkspaceQuantityChangedEventHandler } from '../adapters/primary/handlers/cobiro-pro-workspace-quantity-changed.event-handler';
import { WorkspacesChangedEventHandler } from '../adapters/primary/handlers/workspacew-changed.event-handler';
import { CobiroProWorkspaceCreatedEventHandler } from '../adapters/primary/handlers/cobiro-pro-workspace-created.event-handler';
import { CobiroProWorkspaceSwitchedEventHandler } from '../adapters/primary/handlers/cobiro-pro-workspace-switched.event-handler';

@NgModule({
  imports: [
    WorkspacesStateModule,
    InMemoryWorkspacesContextStorageModule,
    HttpWorkspacesServiceModule,
    HttpTeamsServiceModule,
  ],
  providers: [
    HasWorkspacesGuard,
    provideApplicationEventsHandler(CurrentWorkspaceChangedEventHandler),
    provideApplicationEventsHandler(CobiroProWorkspaceSwitchedEventHandler),
    provideApplicationEventsHandler(CobiroProWorkspaceCreatedEventHandler),
    provideApplicationEventsHandler(CobiroProWorkspaceSelectedEventHandler),
    provideApplicationEventsHandler(CobiroProWorkspaceQuantityChangedEventHandler),
    provideApplicationEventsHandler(WorkspacesChangedEventHandler),
  ],
})
export class CobiroProRewriteWorkspacesProvidersModule {}
